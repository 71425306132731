@import url(//hello.myfonts.net/count/38c2c3);
@charset "UTF-8";
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 768px) {
    .container {
      max-width: 768px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 860px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1600px) {
    .container {
      max-width: 1145px; } }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-ty-1, .col-ty-2, .col-ty-3, .col-ty-4, .col-ty-5, .col-ty-6, .col-ty-7, .col-ty-8, .col-ty-9, .col-ty-10, .col-ty-11, .col-ty-12, .col-ty,
.col-ty-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 480px) {
  .col-ty {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-ty-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-ty-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-ty-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-ty-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-ty-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-ty-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-ty-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-ty-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-ty-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-ty-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-ty-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-ty-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-ty-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-ty-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-ty-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-ty-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-ty-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-ty-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-ty-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-ty-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-ty-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-ty-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-ty-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-ty-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-ty-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-ty-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-ty-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-ty-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-ty-0 {
    margin-left: 0; }
  .offset-ty-1 {
    margin-left: 8.33333%; }
  .offset-ty-2 {
    margin-left: 16.66667%; }
  .offset-ty-3 {
    margin-left: 25%; }
  .offset-ty-4 {
    margin-left: 33.33333%; }
  .offset-ty-5 {
    margin-left: 41.66667%; }
  .offset-ty-6 {
    margin-left: 50%; }
  .offset-ty-7 {
    margin-left: 58.33333%; }
  .offset-ty-8 {
    margin-left: 66.66667%; }
  .offset-ty-9 {
    margin-left: 75%; }
  .offset-ty-10 {
    margin-left: 83.33333%; }
  .offset-ty-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 479.98px) {
  .table-responsive-ty {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-ty > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1599.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #7C8488;
  border-radius: 2px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #000;
    background-color: #fff;
    border-color: #404040;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #000;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 2px; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #006C8D; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 108, 141, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #006C8D;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23006C8D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #006C8D;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 108, 141, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 108, 141, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #006C8D;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23006C8D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #006C8D;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 108, 141, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 108, 141, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #006C8D; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #006C8D; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #006C8D; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #0093c0;
  background-color: #0093c0; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 108, 141, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(0, 108, 141, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #006C8D; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #006C8D; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #006C8D;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 108, 141, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(0, 108, 141, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #E04C23; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(224, 76, 35, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #E04C23;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E04C23' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #E04C23;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 76, 35, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(224, 76, 35, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #E04C23;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E04C23' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #E04C23;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 76, 35, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(224, 76, 35, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #E04C23; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #E04C23; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #E04C23; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e67050;
  background-color: #e67050; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 76, 35, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(224, 76, 35, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #E04C23; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E04C23; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #E04C23;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 76, 35, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(224, 76, 35, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.7rem 30px;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 50px;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 60px; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 40px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #000;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
              box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 479.98px) {
  .navbar-expand-ty > .container,
  .navbar-expand-ty > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 480px) {
  .navbar-expand-ty {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-ty .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-ty .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-ty .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-ty > .container,
    .navbar-expand-ty > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-ty .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-ty .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1599.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1600px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .card-deck {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 0%;
                flex: 1 0 0%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .card-group {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap; }
      .card-group > .card {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 0%;
                flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card {
  overflow: hidden; }
  .accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion .card .card-header {
    margin-bottom: -1px; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 480px) {
  .d-ty-none {
    display: none !important; }
  .d-ty-inline {
    display: inline !important; }
  .d-ty-inline-block {
    display: inline-block !important; }
  .d-ty-block {
    display: block !important; }
  .d-ty-table {
    display: table !important; }
  .d-ty-table-row {
    display: table-row !important; }
  .d-ty-table-cell {
    display: table-cell !important; }
  .d-ty-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-ty-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-3by4::before {
  padding-top: 133.33333%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 480px) {
  .flex-ty-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-ty-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-ty-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-ty-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-ty-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-ty-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-ty-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-ty-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-ty-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-ty-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-ty-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-ty-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-ty-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-ty-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-ty-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-ty-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-ty-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-ty-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-ty-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-ty-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-ty-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-ty-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-ty-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-ty-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-ty-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-ty-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-ty-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-ty-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-ty-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-ty-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-ty-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-ty-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-ty-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-ty-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 480px) {
  .float-ty-left {
    float: left !important; }
  .float-ty-right {
    float: right !important; }
  .float-ty-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1600px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 480px) {
  .m-ty-0 {
    margin: 0 !important; }
  .mt-ty-0,
  .my-ty-0 {
    margin-top: 0 !important; }
  .mr-ty-0,
  .mx-ty-0 {
    margin-right: 0 !important; }
  .mb-ty-0,
  .my-ty-0 {
    margin-bottom: 0 !important; }
  .ml-ty-0,
  .mx-ty-0 {
    margin-left: 0 !important; }
  .m-ty-1 {
    margin: 0.25rem !important; }
  .mt-ty-1,
  .my-ty-1 {
    margin-top: 0.25rem !important; }
  .mr-ty-1,
  .mx-ty-1 {
    margin-right: 0.25rem !important; }
  .mb-ty-1,
  .my-ty-1 {
    margin-bottom: 0.25rem !important; }
  .ml-ty-1,
  .mx-ty-1 {
    margin-left: 0.25rem !important; }
  .m-ty-2 {
    margin: 0.5rem !important; }
  .mt-ty-2,
  .my-ty-2 {
    margin-top: 0.5rem !important; }
  .mr-ty-2,
  .mx-ty-2 {
    margin-right: 0.5rem !important; }
  .mb-ty-2,
  .my-ty-2 {
    margin-bottom: 0.5rem !important; }
  .ml-ty-2,
  .mx-ty-2 {
    margin-left: 0.5rem !important; }
  .m-ty-3 {
    margin: 1rem !important; }
  .mt-ty-3,
  .my-ty-3 {
    margin-top: 1rem !important; }
  .mr-ty-3,
  .mx-ty-3 {
    margin-right: 1rem !important; }
  .mb-ty-3,
  .my-ty-3 {
    margin-bottom: 1rem !important; }
  .ml-ty-3,
  .mx-ty-3 {
    margin-left: 1rem !important; }
  .m-ty-4 {
    margin: 1.5rem !important; }
  .mt-ty-4,
  .my-ty-4 {
    margin-top: 1.5rem !important; }
  .mr-ty-4,
  .mx-ty-4 {
    margin-right: 1.5rem !important; }
  .mb-ty-4,
  .my-ty-4 {
    margin-bottom: 1.5rem !important; }
  .ml-ty-4,
  .mx-ty-4 {
    margin-left: 1.5rem !important; }
  .m-ty-5 {
    margin: 3rem !important; }
  .mt-ty-5,
  .my-ty-5 {
    margin-top: 3rem !important; }
  .mr-ty-5,
  .mx-ty-5 {
    margin-right: 3rem !important; }
  .mb-ty-5,
  .my-ty-5 {
    margin-bottom: 3rem !important; }
  .ml-ty-5,
  .mx-ty-5 {
    margin-left: 3rem !important; }
  .p-ty-0 {
    padding: 0 !important; }
  .pt-ty-0,
  .py-ty-0 {
    padding-top: 0 !important; }
  .pr-ty-0,
  .px-ty-0 {
    padding-right: 0 !important; }
  .pb-ty-0,
  .py-ty-0 {
    padding-bottom: 0 !important; }
  .pl-ty-0,
  .px-ty-0 {
    padding-left: 0 !important; }
  .p-ty-1 {
    padding: 0.25rem !important; }
  .pt-ty-1,
  .py-ty-1 {
    padding-top: 0.25rem !important; }
  .pr-ty-1,
  .px-ty-1 {
    padding-right: 0.25rem !important; }
  .pb-ty-1,
  .py-ty-1 {
    padding-bottom: 0.25rem !important; }
  .pl-ty-1,
  .px-ty-1 {
    padding-left: 0.25rem !important; }
  .p-ty-2 {
    padding: 0.5rem !important; }
  .pt-ty-2,
  .py-ty-2 {
    padding-top: 0.5rem !important; }
  .pr-ty-2,
  .px-ty-2 {
    padding-right: 0.5rem !important; }
  .pb-ty-2,
  .py-ty-2 {
    padding-bottom: 0.5rem !important; }
  .pl-ty-2,
  .px-ty-2 {
    padding-left: 0.5rem !important; }
  .p-ty-3 {
    padding: 1rem !important; }
  .pt-ty-3,
  .py-ty-3 {
    padding-top: 1rem !important; }
  .pr-ty-3,
  .px-ty-3 {
    padding-right: 1rem !important; }
  .pb-ty-3,
  .py-ty-3 {
    padding-bottom: 1rem !important; }
  .pl-ty-3,
  .px-ty-3 {
    padding-left: 1rem !important; }
  .p-ty-4 {
    padding: 1.5rem !important; }
  .pt-ty-4,
  .py-ty-4 {
    padding-top: 1.5rem !important; }
  .pr-ty-4,
  .px-ty-4 {
    padding-right: 1.5rem !important; }
  .pb-ty-4,
  .py-ty-4 {
    padding-bottom: 1.5rem !important; }
  .pl-ty-4,
  .px-ty-4 {
    padding-left: 1.5rem !important; }
  .p-ty-5 {
    padding: 3rem !important; }
  .pt-ty-5,
  .py-ty-5 {
    padding-top: 3rem !important; }
  .pr-ty-5,
  .px-ty-5 {
    padding-right: 3rem !important; }
  .pb-ty-5,
  .py-ty-5 {
    padding-bottom: 3rem !important; }
  .pl-ty-5,
  .px-ty-5 {
    padding-left: 3rem !important; }
  .m-ty-n1 {
    margin: -0.25rem !important; }
  .mt-ty-n1,
  .my-ty-n1 {
    margin-top: -0.25rem !important; }
  .mr-ty-n1,
  .mx-ty-n1 {
    margin-right: -0.25rem !important; }
  .mb-ty-n1,
  .my-ty-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-ty-n1,
  .mx-ty-n1 {
    margin-left: -0.25rem !important; }
  .m-ty-n2 {
    margin: -0.5rem !important; }
  .mt-ty-n2,
  .my-ty-n2 {
    margin-top: -0.5rem !important; }
  .mr-ty-n2,
  .mx-ty-n2 {
    margin-right: -0.5rem !important; }
  .mb-ty-n2,
  .my-ty-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-ty-n2,
  .mx-ty-n2 {
    margin-left: -0.5rem !important; }
  .m-ty-n3 {
    margin: -1rem !important; }
  .mt-ty-n3,
  .my-ty-n3 {
    margin-top: -1rem !important; }
  .mr-ty-n3,
  .mx-ty-n3 {
    margin-right: -1rem !important; }
  .mb-ty-n3,
  .my-ty-n3 {
    margin-bottom: -1rem !important; }
  .ml-ty-n3,
  .mx-ty-n3 {
    margin-left: -1rem !important; }
  .m-ty-n4 {
    margin: -1.5rem !important; }
  .mt-ty-n4,
  .my-ty-n4 {
    margin-top: -1.5rem !important; }
  .mr-ty-n4,
  .mx-ty-n4 {
    margin-right: -1.5rem !important; }
  .mb-ty-n4,
  .my-ty-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-ty-n4,
  .mx-ty-n4 {
    margin-left: -1.5rem !important; }
  .m-ty-n5 {
    margin: -3rem !important; }
  .mt-ty-n5,
  .my-ty-n5 {
    margin-top: -3rem !important; }
  .mr-ty-n5,
  .mx-ty-n5 {
    margin-right: -3rem !important; }
  .mb-ty-n5,
  .my-ty-n5 {
    margin-bottom: -3rem !important; }
  .ml-ty-n5,
  .mx-ty-n5 {
    margin-left: -3rem !important; }
  .m-ty-auto {
    margin: auto !important; }
  .mt-ty-auto,
  .my-ty-auto {
    margin-top: auto !important; }
  .mr-ty-auto,
  .mx-ty-auto {
    margin-right: auto !important; }
  .mb-ty-auto,
  .my-ty-auto {
    margin-bottom: auto !important; }
  .ml-ty-auto,
  .mx-ty-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 480px) {
  .text-ty-left {
    text-align: left !important; }
  .text-ty-right {
    text-align: right !important; }
  .text-ty-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@font-face {
  font-family: 'lg';
  src: url("/static/rey-technology/fonts/lightgallery/lg.eot?n1z373");
  src: url("/static/rey-technology/fonts/lightgallery/lg.eot?#iefixn1z373") format("embedded-opentype"), url("/static/rey-technology/fonts/lightgallery/lg.woff?n1z373") format("woff"), url("/static/rey-technology/fonts/lightgallery/lg.ttf?n1z373") format("truetype"), url("/static/rey-technology/fonts/lightgallery/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal; }

.lg-icon {
  font-family: 'lg';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  border: none;
  outline: none; }
  .lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }
  .lg-actions .lg-next:before {
    content: "\E095"; }

.lg-actions .lg-prev {
  left: 20px; }
  .lg-actions .lg-prev:after {
    content: "\E094"; }

@-webkit-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-webkit-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #000; }
  .lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .lg-toolbar .lg-icon:hover {
      color: #FFF; }
  .lg-toolbar .lg-close:after {
    content: "\E070"; }
  .lg-toolbar .lg-download:after {
    content: "\E0F2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }
  .lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold; }
  .lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -webkit-transition: opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transition: opacity 250ms cubic-bezier(0, 0, 0.25, 1), -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: opacity 250ms cubic-bezier(0, 0, 0.25, 1), -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1), -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }
  @media (min-width: 1025px) {
    .lg-outer .lg-thumb-item {
      -webkit-transition: border-color 0.25s ease;
      transition: border-color 0.25s ease; } }
  .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #a90707; }
  .lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toogle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px; }
  .lg-outer .lg-toogle-thumb:after {
    content: "\E1FF"; }
  .lg-outer .lg-toogle-thumb:hover {
    color: #FFF; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }
  .lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer; }

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("/static/rey-technology/images/lightgallery/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("/static/rey-technology/images/lightgallery/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("/static/rey-technology/images/lightgallery/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("/static/rey-technology/images/lightgallery/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("/static/rey-technology/images/lightgallery/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s; }
  .lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0; }
  .lg-progress-bar.lg-start .lg-progress {
    width: 100%; }
  .lg-show-autoplay .lg-progress-bar {
    opacity: 1; }

.lg-autoplay-button:after {
  content: "\E01D"; }
  .lg-show-autoplay .lg-autoplay-button:after {
    content: "\E01A"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\E311"; }

#lg-actual-size {
  font-size: 20px; }
  #lg-actual-size:after {
    content: "\E033"; }

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none; }
  #lg-zoom-out:after {
    content: "\E312"; }
  .lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }
  .lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }
  .lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    -webkit-box-shadow: 0 0 0 2px white inset;
            box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }
  .lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
          box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -webkit-transition: -webkit-box-shadow 0.3s ease 0s;
  transition: -webkit-box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
  width: 12px; }
  .lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
    -webkit-box-shadow: 0 0 0 8px white inset;
            box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\E20C"; }
  .lg-fullscreen-on .lg-fullscreen:after {
    content: "\E20D"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -webkit-transition: visibility 0s linear 0.5s, opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s;
  transition: visibility 0s linear 0.5s, opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s; }
  .lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #FFF;
    right: 16px;
    top: -16px; }
  .lg-outer .lg-dropdown > li:last-child {
    margin-bottom: 0px; }
  .lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
    color: #333; }
  .lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px; }
    .lg-outer .lg-dropdown a:hover {
      background-color: rgba(0, 0, 0, 0.07); }
  .lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle; }
  .lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto; }

.lg-outer #lg-share {
  position: relative; }
  .lg-outer #lg-share:after {
    content: "\E80D"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }
  .lg-outer #lg-share-facebook .lg-icon:after {
    content: "\E901"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }
  .lg-outer #lg-share-twitter .lg-icon:after {
    content: "\E904"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }
  .lg-outer #lg-share-googleplus .lg-icon:after {
    content: "\E902"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }
  .lg-outer #lg-share-pinterest .lg-icon:after {
    content: "\E903"; }

.lg-group:after {
  content: "";
  display: table;
  clear: both; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-outer * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .lg-outer.lg-visible {
    opacity: 1; }
  .lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important; }
  .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1; }
  .lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%; }
  .lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap; }
  .lg-outer .lg-item {
    background: url("/static/rey-technology/images/lightgallery/loading.gif") no-repeat scroll center center transparent;
    display: none !important; }
  .lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important; }
  .lg-outer.lg-css .lg-current {
    display: inline-block !important; }
  .lg-outer .lg-item, .lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%; }
    .lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }
  .lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .lg-outer .lg-item.lg-complete {
    background-image: none; }
  .lg-outer .lg-item.lg-current {
    z-index: 1060; }
  .lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important; }
  .lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-empty-html {
    display: none; }
  .lg-outer.lg-hide-download #lg-download {
    display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-backdrop.in {
    opacity: 1; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }
  .lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1; }
  .lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -webkit-transition: opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  overflow-x: hidden;
  overflow-y: auto; }
  .sidr.right {
    left: auto;
    right: -260px; }
  .sidr.left {
    left: -260px;
    right: auto; }

/**
    GAP WATCHER CORE FILE

    Mission: Define all the gaps for all elements with css.
    In. every. f***. breakpoint.
    Use the grid.js helper to inject forcing gaps for floating elements.

    Set $content-gap-debug true in variables to debug your layout!

    1. Be sure all your custom bricks does have a .toolbox-element class
    2. If you need a custom gap watcher you could also use the .dc-gap-element class

 */
.dc-gap-element:not(.prevent-bottom-space),
.dc-last-gap-element:not(.prevent-bottom-space) {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .dc-gap-element:not(.prevent-bottom-space),
    .dc-last-gap-element:not(.prevent-bottom-space) {
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .dc-gap-element:not(.prevent-bottom-space),
    .dc-last-gap-element:not(.prevent-bottom-space) {
      margin-bottom: 40px; } }
  @media (min-width: 992px) {
    .dc-gap-element:not(.prevent-bottom-space),
    .dc-last-gap-element:not(.prevent-bottom-space) {
      margin-bottom: 40px; } }
  @media (min-width: 1200px) {
    .dc-gap-element:not(.prevent-bottom-space),
    .dc-last-gap-element:not(.prevent-bottom-space) {
      margin-bottom: 50px; } }
  @media (min-width: 1600px) {
    .dc-gap-element:not(.prevent-bottom-space),
    .dc-last-gap-element:not(.prevent-bottom-space) {
      margin-bottom: 50px; } }

.dc-gap-element:last-child:not(.force-margin):not(.dc-last-gap-element),
.dc-last-gap-element:last-child:not(.force-margin):not(.dc-last-gap-element) {
  margin-bottom: 0 !important; }

.toolbox-element:not(.toolbox-headline):not(.prevent-bottom-space):not(.toolbox-anchor):not(.toolbox-spacer):not(.toolbox-separator) {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .toolbox-element:not(.toolbox-headline):not(.prevent-bottom-space):not(.toolbox-anchor):not(.toolbox-spacer):not(.toolbox-separator) {
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .toolbox-element:not(.toolbox-headline):not(.prevent-bottom-space):not(.toolbox-anchor):not(.toolbox-spacer):not(.toolbox-separator) {
      margin-bottom: 40px; } }
  @media (min-width: 992px) {
    .toolbox-element:not(.toolbox-headline):not(.prevent-bottom-space):not(.toolbox-anchor):not(.toolbox-spacer):not(.toolbox-separator) {
      margin-bottom: 40px; } }
  @media (min-width: 1200px) {
    .toolbox-element:not(.toolbox-headline):not(.prevent-bottom-space):not(.toolbox-anchor):not(.toolbox-spacer):not(.toolbox-separator) {
      margin-bottom: 50px; } }
  @media (min-width: 1600px) {
    .toolbox-element:not(.toolbox-headline):not(.prevent-bottom-space):not(.toolbox-anchor):not(.toolbox-spacer):not(.toolbox-separator) {
      margin-bottom: 50px; } }

.toolbox-element.toolbox-container > .container > .container-inner > .toolbox-element:last-child:not(.force-margin), .toolbox-element.toolbox-container > .container-fluid > .container-inner > .toolbox-element:last-child:not(.force-margin) {
  margin-bottom: 0; }

.toolbox-element.toolbox-columns .toolbox-column > .toolbox-element:last-child:not(.force-margin) {
  margin-bottom: 0; }

.toolbox-element.toolbox-accordion.component-accordion .card-body > .toolbox-element:last-child:not(.force-margin), .toolbox-element.toolbox-accordion.component-mobile-accordion .card-body > .toolbox-element:last-child:not(.force-margin) {
  margin-bottom: 0; }

.toolbox-element.toolbox-accordion.component-tab .tab-pane > .toolbox-element:last-child:not(.force-margin) {
  margin-bottom: 0; }

.toolbox-element.toolbox-slide-columns .slide-column > .toolbox-element:last-child:not(.force-margin) {
  margin-bottom: 0; }

.toolbox-element .toolbox-content > p:last-child, .toolbox-element .wysiwyg-field > p:last-child {
  margin-bottom: 0; }

.toolbox-element:last-child:not(.force-margin):not(.force-bottom-space) {
  margin-bottom: 0 !important; }

.force-top-space {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .force-top-space {
      margin-top: 30px; } }
  @media (min-width: 768px) {
    .force-top-space {
      margin-top: 40px; } }
  @media (min-width: 992px) {
    .force-top-space {
      margin-top: 40px; } }
  @media (min-width: 1200px) {
    .force-top-space {
      margin-top: 50px; } }
  @media (min-width: 1600px) {
    .force-top-space {
      margin-top: 50px; } }

.force-bottom-space {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .force-bottom-space {
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .force-bottom-space {
      margin-bottom: 40px; } }
  @media (min-width: 992px) {
    .force-bottom-space {
      margin-bottom: 40px; } }
  @media (min-width: 1200px) {
    .force-bottom-space {
      margin-bottom: 50px; } }
  @media (min-width: 1600px) {
    .force-bottom-space {
      margin-bottom: 50px; } }

.btn {
  text-transform: uppercase;
  border: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  padding: 0.8rem 30px 0.6rem; }
  .btn.btn-primary {
    background: #006C8D;
    color: #fff; }
    .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:active:focus {
      background: #4B97AE;
      color: #fff;
      outline: none; }
  .btn.btn-light {
    color: #006C8D;
    border: 2px solid transparent; }
    .btn.btn-light:hover, .btn.btn-light:focus, .btn.btn-light:active, .btn.btn-light:active:focus {
      background: #006C8D;
      color: #fff;
      outline: none;
      border-color: #fff; }

textarea.form-control {
  min-height: 150px; }

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url("/static/rey-technology/images/select-arrow.svg") center right 10px no-repeat;
  padding-right: 18px; }

select.form-control::-ms-expand {
  display: none; }

input.form-control:focus, textarea.form-control:focus, select.form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #393E41; }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: inherit; }

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: none;
          box-shadow: none; }

.custom-control-label::before {
  border-color: #7C8488; }

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #393E41;
  background-color: #393E41; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  border-color: #7C8488;
  background-color: #7C8488; }

.custom-control-label::before, .custom-control-label::after {
  top: 0.15rem; }

.w-66 {
  width: 66%; }

.w-33 {
  width: 33%; }

.gutter-4 {
  margin-left: -4px;
  margin-right: -4px; }
  .gutter-4 > .col,
  .gutter-4 > [class*="col-"] {
    padding-right: 2px;
    padding-left: 2px; }

.gutter-30 {
  margin-left: -30px;
  margin-right: -30px; }
  .gutter-30 > .col,
  .gutter-30 > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px; }

.btn {
  text-transform: uppercase;
  border: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  padding: 0.8rem 30px 0.6rem; }
  .btn.btn-primary {
    background: #006C8D;
    color: #fff; }
    .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:active:focus {
      background: #4B97AE;
      color: #fff;
      outline: none; }
  .btn.btn-light {
    color: #006C8D;
    border: 2px solid transparent; }
    .btn.btn-light:hover, .btn.btn-light:focus, .btn.btn-light:active, .btn.btn-light:active:focus {
      background: #006C8D;
      color: #fff;
      outline: none;
      border-color: #fff; }

textarea.form-control {
  min-height: 150px; }

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url("/static/rey-technology/images/select-arrow.svg") center right 10px no-repeat;
  padding-right: 18px; }

select.form-control::-ms-expand {
  display: none; }

input.form-control:focus, textarea.form-control:focus, select.form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #393E41; }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: inherit; }

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: none;
          box-shadow: none; }

.custom-control-label::before {
  border-color: #7C8488; }

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #393E41;
  background-color: #393E41; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  border-color: #7C8488;
  background-color: #7C8488; }

.custom-control-label::before, .custom-control-label::after {
  top: 0.15rem; }

.w-66 {
  width: 66%; }

.w-33 {
  width: 33%; }

.gutter-4 {
  margin-left: -4px;
  margin-right: -4px; }
  .gutter-4 > .col,
  .gutter-4 > [class*="col-"] {
    padding-right: 2px;
    padding-left: 2px; }

.gutter-30 {
  margin-left: -30px;
  margin-right: -30px; }
  .gutter-30 > .col,
  .gutter-30 > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px; }

/**
 * @license
 * MyFonts Webfont Build ID 3719875, 2019-02-14T08:43:14-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: SofiaPro-Bold by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/bold/
 * Licensed pageviews: 20,000
 *
 * Webfont: SofiaProLight by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/light/
 * Licensed pageviews: 10,000
 *
 * Webfont: SofiaProMedium by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/medium/
 * Licensed pageviews: 20,000
 *
 * Webfont: SofiaProRegular by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/regular/
 * Licensed pageviews: 20,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3719875
 * Webfonts copyright: Copyright &#x00A9; Olivier Gourvat - Mostardesign Type Foundry, 2016. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'SofiaPro';
  font-weight: 700;
  src: url("/static/rey-technology/fonts/38C2C3_0_0.eot");
  src: url("/static/rey-technology/fonts/38C2C3_0_0.eot?#iefix") format("embedded-opentype"), url("/static/rey-technology/fonts/38C2C3_0_0.woff2") format("woff2"), url("/static/rey-technology/fonts/38C2C3_0_0.woff") format("woff"), url("/static/rey-technology/fonts/38C2C3_0_0.ttf") format("truetype"); }

@font-face {
  font-family: 'SofiaPro';
  font-weight: 300;
  src: url("/static/rey-technology/fonts/38C2C3_1_0.eot");
  src: url("/static/rey-technology/fonts/38C2C3_1_0.eot?#iefix") format("embedded-opentype"), url("/static/rey-technology/fonts/38C2C3_1_0.woff2") format("woff2"), url("/static/rey-technology/fonts/38C2C3_1_0.woff") format("woff"), url("/static/rey-technology/fonts/38C2C3_1_0.ttf") format("truetype"); }

@font-face {
  font-family: 'SofiaPro';
  font-weight: 500;
  src: url("/static/rey-technology/fonts/38C2C3_2_0.eot");
  src: url("/static/rey-technology/fonts/38C2C3_2_0.eot?#iefix") format("embedded-opentype"), url("/static/rey-technology/fonts/38C2C3_2_0.woff2") format("woff2"), url("/static/rey-technology/fonts/38C2C3_2_0.woff") format("woff"), url("/static/rey-technology/fonts/38C2C3_2_0.ttf") format("truetype"); }

@font-face {
  font-family: 'SofiaPro';
  font-weight: 400;
  src: url("/static/rey-technology/fonts/38C2C3_3_0.eot");
  src: url("/static/rey-technology/fonts/38C2C3_3_0.eot?#iefix") format("embedded-opentype"), url("/static/rey-technology/fonts/38C2C3_3_0.woff2") format("woff2"), url("/static/rey-technology/fonts/38C2C3_3_0.woff") format("woff"), url("/static/rey-technology/fonts/38C2C3_3_0.ttf") format("truetype"); }

@font-face {
  font-family: "basicfont";
  src: url(/static/rey-technology/fonts/basicfont.417e818d.eot);
  src: url(/static/rey-technology/fonts/basicfont.417e818d.eot#iefix) format("embedded-opentype"), url(/static/rey-technology/fonts/basicfont.07573fa9.ttf) format("truetype"), url(/static/rey-technology/fonts/basicfont.341c2982.woff) format("woff"), url(/static/rey-technology/images/basicfont.03402128.svg#basicfont) format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="dd-icon-"], [class*=" dd-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "basicfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.dd-icon-angle-double-left:before {
  content: "\E900"; }

.dd-icon-angle-double-right:before {
  content: "\E901"; }

.dd-icon-chevron-down:before {
  content: "\E902"; }

.dd-icon-chevron-left:before {
  content: "\E903"; }

.dd-icon-chevron-right:before {
  content: "\E904"; }

.dd-icon-chevron-up:before {
  content: "\E905"; }

.dd-icon-external-link:before {
  content: "\E906"; }

.dd-icon-facebook:before {
  content: "\E907"; }

.dd-icon-file-audio-o:before {
  content: "\E908"; }

.dd-icon-file-excel-o:before {
  content: "\E909"; }

.dd-icon-file-image-o:before {
  content: "\E90A"; }

.dd-icon-file-o:before {
  content: "\E90B"; }

.dd-icon-file-pdf-o:before {
  content: "\E90C"; }

.dd-icon-file-powerpoint-o:before {
  content: "\E90D"; }

.dd-icon-file-text-o:before {
  content: "\E90E"; }

.dd-icon-file-video-o:before {
  content: "\E90F"; }

.dd-icon-file-word-o:before {
  content: "\E910"; }

.dd-icon-google-plus:before {
  content: "\E912"; }

.dd-icon-link:before {
  content: "\E913"; }

.dd-icon-linkedin:before {
  content: "\E914"; }

.dd-icon-play-circle-o:before {
  content: "\E915"; }

.dd-icon-square:before {
  content: "\E916"; }

.dd-icon-twitter:before {
  content: "\E917"; }

.dd-icon-xing:before {
  content: "\E918"; }

.dd-icon-youtube2:before {
  content: "\E919"; }

.dd-icon-instagram:before {
  content: "\E911"; }

@-webkit-keyframes zoomOut {
  from {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0; }
  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; } }

@keyframes zoomOut {
  from {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0; }
  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; } }

html {
  height: 100%; }

body {
  min-height: 100%;
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
  font-size: 15px;
  line-height: 1.4em;
  display: grid;
  grid-template-rows: 1fr auto; }
  @media (min-width: 768px) {
    body {
      font-size: 16px; } }
  @media (min-width: 1200px) {
    body {
      font-size: 17px; } }

#wrapper {
  overflow: hidden; }

.footer-wrapper {
  grid-row-start: 2;
  grid-row-end: 3; }

.dd-main-content {
  padding-bottom: 80px; }

.dd-breadcrumb {
  display: none; }
  @media (min-width: 992px) {
    .dd-breadcrumb {
      display: block;
      font-size: smaller;
      padding: 2rem 0; } }
  .dd-breadcrumb .breadcrumb {
    background: transparent;
    padding: 0; }

.dd-error-wrapper .dd-error-text-small {
  font-family: inherit;
  font-size: 35px;
  line-height: .5em;
  margin-top: 1em; }

.dd-error-wrapper .dd-error-text {
  font-family: inherit;
  font-size: 100px;
  line-height: 1em;
  margin-bottom: 1rem; }

.dd-sitemap-wrapper {
  padding-bottom: 2rem; }
  .dd-sitemap-wrapper ul.dd-sitemap ul {
    padding-left: 1rem; }
  .dd-sitemap-wrapper ul.dd-sitemap li a {
    display: block;
    padding: 0.5rem 0;
    border-bottom: 1px solid #ced4da;
    color: #000; }
    .dd-sitemap-wrapper ul.dd-sitemap li a:hover, .dd-sitemap-wrapper ul.dd-sitemap li a:focus {
      color: #006C8D;
      text-decoration: none; }

.breadcrumb-wrapper {
  padding: 10px 0; }
  @media (min-width: 768px) {
    .breadcrumb-wrapper {
      padding: 24px 0 30px; } }

.breadcrumb {
  display: none; }
  @media (min-width: 768px) {
    .breadcrumb {
      display: block;
      font-size: 15px;
      color: #006C8D; }
      .breadcrumb a {
        color: #7C8488; }
        .breadcrumb a:focus, .breadcrumb a:hover, .breadcrumb a.active {
          text-decoration: none; }
      .breadcrumb .arrow {
        font-size: 12px;
        padding-left: 24px; } }

html, body {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
  font-size: 15px;
  line-height: 1.4em; }
  @media (min-width: 768px) {
    html, body {
      font-size: 16px; } }
  @media (min-width: 1200px) {
    html, body {
      font-size: 17px; } }

p, .p {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
  font-size: 15px;
  line-height: 1.4em;
  margin: 0 0 20px 0; }
  @media (min-width: 768px) {
    p, .p {
      font-size: 16px; } }
  @media (min-width: 1200px) {
    p, .p {
      font-size: 17px; } }

a, .a {
  -webkit-transition: color .35s ease-out;
  transition: color .35s ease-out;
  color: #006C8D; }
  a:hover, a:focus, a:active, .a:hover, .a:focus, .a:active {
    color: #006C8D;
    text-decoration: underline; }
  a.dd-link-internal, .a.dd-link-internal {
    position: relative;
    padding-right: 1.3em; }
    a.dd-link-internal:after, .a.dd-link-internal:after {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: "basicfont" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\E913";
      padding-left: 3px;
      color: #006C8D;
      position: absolute;
      top: 1px;
      right: 0; }
  a.dd-link-external, .a.dd-link-external {
    position: relative;
    padding-right: 1.3em; }
    a.dd-link-external:after, .a.dd-link-external:after {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: "basicfont" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\E906";
      padding-left: 5px;
      color: #006C8D;
      position: absolute;
      top: 1px;
      right: 0; }
  a.block-link, a.block-link:hover, a.block-link:focus, .a.block-link, .a.block-link:hover, .a.block-link:focus {
    text-decoration: none; }

.primary, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: #006C8D; }

h1, .h1 {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: none;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.2em;
  margin: 0 0 23px 0; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 38px;
      margin: 0 0 21px 0; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 48px; } }

h2, .h2 {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 25px;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0 0 12px 0; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 28px;
      margin: 0 0 13px 0; } }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 36px;
      margin: 0 0 14px 0; } }

h3, .h3 {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0 0 14px 0; }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 28px;
      margin: 0 0 16px 0; } }

h4, .h4 {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0 0 12px 0; }
  @media (min-width: 1200px) {
    h4, .h4 {
      text-transform: uppercase;
      font-size: 22px;
      margin: 0 0 15px 0; } }

h5, .h5 {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0 0 9px 0; }
  @media (min-width: 1200px) {
    h5, .h5 {
      font-size: 20px;
      margin: 0 0 12px 0; } }

h6, .h6 {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
  font-size: 15px;
  line-height: 1.4em;
  font-weight: 400;
  margin-bottom: 0; }
  @media (min-width: 768px) {
    h6, .h6 {
      font-size: 16px; } }
  @media (min-width: 1200px) {
    h6, .h6 {
      font-size: 17px; } }

.big-title {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: none;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2em;
  margin: 0 0 20px 0; }
  @media (min-width: 768px) {
    .big-title {
      font-size: 50px; } }
  @media (min-width: 1200px) {
    .big-title {
      font-size: 75px; } }

address, .address {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
  font-size: 15px;
  line-height: 1.4em; }
  @media (min-width: 768px) {
    address, .address {
      font-size: 16px; } }
  @media (min-width: 1200px) {
    address, .address {
      font-size: 17px; } }

.lead, .lead p, .lead li p {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 17px;
  line-height: 1.6em;
  font-weight: 400;
  color: #7C8488; }
  @media (min-width: 768px) {
    .lead, .lead p, .lead li p {
      font-size: 18px; } }
  @media (min-width: 1200px) {
    .lead, .lead p, .lead li p {
      font-size: 20px; } }

.text-small, .text-small p, .text-small li p {
  font-family: "SofiaPro", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  line-height: 1.6; }
  @media (min-width: 768px) {
    .text-small, .text-small p, .text-small li p {
      font-size: 13px; } }
  @media (min-width: 1200px) {
    .text-small, .text-small p, .text-small li p {
      font-size: 14px; } }

ul, ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.toolbox-content ul, .list-styled ul, .wysiwyg ul {
  list-style-type: none;
  margin: 0 0 25px 0;
  padding: 0; }
  .toolbox-content ul li, .list-styled ul li, .wysiwyg ul li {
    line-height: 1.4em;
    padding-left: 30px;
    margin-bottom: 15px;
    list-style: none;
    display: block;
    position: relative; }
    .toolbox-content ul li:before, .list-styled ul li:before, .wysiwyg ul li:before {
      position: absolute;
      left: 0;
      top: 50%;
      content: ' ';
      display: inline-block;
      width: 22px;
      height: 22px;
      color: #000;
      background: #006C8D url(/static/rey-technology/images/list-style.8ac0d9fb.svg) center center no-repeat;
      border-radius: 50%;
      text-align: center;
      -webkit-transform: translateY(-55%);
              transform: translateY(-55%); }
    .toolbox-content ul li ul, .list-styled ul li ul, .wysiwyg ul li ul {
      margin: 5px 0; }
  .toolbox-content ul.line-style li, .list-styled ul.line-style li, .wysiwyg ul.line-style li {
    margin: 10px 0; }
    .toolbox-content ul.line-style li:before, .list-styled ul.line-style li:before, .wysiwyg ul.line-style li:before {
      content: '-';
      background: none;
      top: 0;
      left: 5px; }

ul.list-styled {
  list-style-type: none;
  margin: 0 0 25px 0;
  padding: 0; }
  ul.list-styled li {
    line-height: 1.4em;
    padding-left: 30px;
    margin-bottom: 15px;
    list-style: none;
    display: block;
    position: relative; }
    ul.list-styled li:before {
      position: absolute;
      left: 0;
      top: 50%;
      content: ' ';
      display: inline-block;
      width: 22px;
      height: 22px;
      color: #000;
      background: #006C8D url(/static/rey-technology/images/list-style.8ac0d9fb.svg) center center no-repeat;
      border-radius: 50%;
      text-align: center;
      -webkit-transform: translateY(-55%);
              transform: translateY(-55%); }
    ul.list-styled li ul {
      margin: 5px 0; }
  ul.list-styled.line-style li {
    margin: 10px 0; }
    ul.list-styled.line-style li:before {
      content: '-';
      background: none;
      top: 0;
      left: 5px; }

.toolbox-content ol, .list-styled ol, .wysiwyg ol {
  padding: 0;
  margin: 0 0 25px 0;
  counter-reset: item;
  list-style-type: none; }
  .toolbox-content ol li, .list-styled ol li, .wysiwyg ol li {
    line-height: 1.4em;
    padding-left: 20px;
    margin-bottom: 15px;
    list-style: none;
    display: block;
    position: relative; }
    .toolbox-content ol li:before, .list-styled ol li:before, .wysiwyg ol li:before {
      position: absolute;
      left: 0;
      top: 0;
      content: counter(item);
      counter-increment: item;
      display: inline-block;
      width: 20px;
      color: #006C8D; }
    .toolbox-content ol li ol, .list-styled ol li ol, .wysiwyg ol li ol {
      margin: 5px 0; }

ol.list-styled {
  padding: 0;
  margin: 0 0 25px 0;
  counter-reset: item;
  list-style-type: none; }
  ol.list-styled li {
    line-height: 1.4em;
    padding-left: 20px;
    margin-bottom: 15px;
    list-style: none;
    display: block;
    position: relative; }
    ol.list-styled li:before {
      position: absolute;
      left: 0;
      top: 0;
      content: counter(item);
      counter-increment: item;
      display: inline-block;
      width: 20px;
      color: #006C8D; }
    ol.list-styled li ol {
      margin: 5px 0; }

ul.list-unstyled, ul.list-unstyled li, ul.list-unstyled ul, ul.list-unstyled ol, ol.list-unstyled, ol.list-unstyled li, ol.list-unstyled ul, ol.list-unstyled ol {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none; }

.dd-header-color {
  background: #fff; }

.dd-header-content {
  overflow: hidden; }

.dd-header {
  padding: 25px 0 34px;
  background: #006C8D center center no-repeat;
  background-size: cover;
  width: 100vw;
  position: fixed;
  left: 0;
  z-index: 5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100px;
  -webkit-transition: height .4s, padding .4s;
  transition: height .4s, padding .4s; }
  .sticky-scrolled .dd-header {
    height: 70px;
    padding: 15px; }
  @media (min-width: 992px) {
    .dd-header {
      height: 127px; }
      .sticky-scrolled .dd-header {
        height: 86px;
        padding-top: 20px; } }
  .dd-header.header-slider-header {
    background: transparent;
    -webkit-transition: background .4s;
    transition: background .4s; }
    .sticky-scrolled .dd-header.header-slider-header {
      background: #006C8D; }
    .dd-header.header-slider-header + .content-wrapper {
      margin-top: 0; }
      .sticky-scrolled .dd-header.header-slider-header + .content-wrapper {
        margin-top: 0; }
  .dd-header + .content-wrapper {
    position: relative;
    z-index: 1;
    margin-top: 100px;
    -webkit-transition: margin-top .4s;
    transition: margin-top .4s; }
    .sticky-scrolled .dd-header + .content-wrapper {
      margin-top: 70px; }
    @media (min-width: 992px) {
      .dd-header + .content-wrapper {
        margin-top: 127px; }
        .sticky-scrolled .dd-header + .content-wrapper {
          margin-top: 86px; } }
  .dd-header .dd-logo {
    display: block; }
    .dd-header .dd-logo img {
      width: 110px;
      height: auto;
      -webkit-transition: width .4s;
      transition: width .4s; }
      .dd-header .dd-logo img.ie {
        display: none; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .dd-header .dd-logo img.normal--solution, .dd-header .dd-logo img.normal {
          display: none; }
        .dd-header .dd-logo img.ie {
          display: block; } }
      .sticky-scrolled .dd-header .dd-logo img {
        width: 94px; }
      @media (min-width: 768px) {
        .dd-header .dd-logo img {
          width: 122px; }
          .sticky-scrolled .dd-header .dd-logo img {
            width: 94px; } }
      @media (min-width: 992px) {
        .dd-header .dd-logo img {
          width: 180px; }
          .sticky-scrolled .dd-header .dd-logo img {
            width: 111px; } }
  .dd-header .dd-gnav {
    -webkit-transition: opacity .4s, -webkit-transform .4s;
    transition: opacity .4s, -webkit-transform .4s;
    transition: opacity .4s, transform .4s;
    transition: opacity .4s, transform .4s, -webkit-transform .4s; }
    @media (min-width: 992px) {
      .sticky-scrolled .dd-header .dd-gnav {
        opacity: 0;
        -webkit-transform: translateY(-35px);
                transform: translateY(-35px); } }
  .dd-header .dd-hnav {
    display: none;
    margin-top: 23px;
    float: right;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s; }
    @media (min-width: 992px) {
      .dd-header .dd-hnav {
        display: block; } }
    .sticky-scrolled .dd-header .dd-hnav {
      -webkit-transform: translateY(-35px);
              transform: translateY(-35px); }
    .dd-header .dd-hnav ul ul {
      display: none; }
      .dd-header .dd-hnav ul ul li a {
        color: #fff;
        -webkit-transition: background-color .3s;
        transition: background-color .3s; }
    .dd-header .dd-hnav li:hover > ul {
      display: block; }
    .dd-header .dd-hnav > ul > li {
      float: left;
      margin-left: 15px;
      position: relative;
      border-bottom: 2px solid transparent;
      -webkit-transition: border-color .3s;
      transition: border-color .3s; }
      @media (min-width: 1600px) {
        .dd-header .dd-hnav > ul > li {
          margin-left: 37px; } }
      .dd-header .dd-hnav > ul > li:hover {
        border-color: #fff; }
      .dd-header .dd-hnav > ul > li > a {
        color: #fff;
        padding-bottom: 3px;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px; }
        @media (min-width: 1600px) {
          .dd-header .dd-hnav > ul > li > a {
            font-size: 18px; } }
        .dd-header .dd-hnav > ul > li > a:hover, .dd-header .dd-hnav > ul > li > a:focus, .dd-header .dd-hnav > ul > li > a:active, .dd-header .dd-hnav > ul > li > a.active {
          text-decoration: none; }
      .dd-header .dd-hnav > ul > li > ul {
        padding: 12px 0;
        position: absolute;
        top: calc(100% + 2px);
        width: 210px;
        background: #006C8D;
        display: none; }
        .dd-header .dd-hnav > ul > li > ul > li {
          position: relative; }
          .dd-header .dd-hnav > ul > li > ul > li > a {
            padding: 6px 12px;
            display: block; }
            .dd-header .dd-hnav > ul > li > ul > li > a:hover, .dd-header .dd-hnav > ul > li > ul > li > a:focus {
              text-decoration: none;
              background: #4B97AE; }
          .dd-header .dd-hnav > ul > li > ul > li > ul {
            padding: 12px 0;
            position: absolute;
            width: 210px;
            background: #4B97AE;
            left: 100%;
            top: -12px; }
            .dd-header .dd-hnav > ul > li > ul > li > ul > li > a {
              padding: 6px 12px;
              display: block; }
              .dd-header .dd-hnav > ul > li > ul > li > ul > li > a:hover, .dd-header .dd-hnav > ul > li > ul > li > ul > li > a:focus {
                text-decoration: none;
                background: #006C8D; }
            .dd-header .dd-hnav > ul > li > ul > li > ul > li ul {
              display: block; }
              .dd-header .dd-hnav > ul > li > ul > li > ul > li ul li a {
                padding: 6px 12px;
                display: block; }
                .dd-header .dd-hnav > ul > li > ul > li > ul > li ul li a:hover, .dd-header .dd-hnav > ul > li > ul > li > ul > li ul li a:focus {
                  text-decoration: none;
                  background: #006C8D; }
                .dd-header .dd-hnav > ul > li > ul > li > ul > li ul li a:before {
                  content: '-  '; }
      .dd-header .dd-hnav > ul > li:last-child > ul {
        right: 0; }
        .dd-header .dd-hnav > ul > li:last-child > ul > li > ul {
          left: auto;
          right: 100%; }
      @media (max-width: 1599.98px) {
        .dd-header .dd-hnav > ul > li:nth-last-child(2) > ul > li > ul {
          left: auto;
          right: 100%; } }
  .dd-header .dd-lnav {
    float: right; }
    @media (max-width: 991.98px) {
      .dd-header .dd-lnav {
        margin-top: .9em; } }
    .dd-header .dd-lnav select {
      background: url("/static/rey-technology/images/select-arrow-inv.svg") center right no-repeat;
      border-radius: 0;
      border: 1px solid transparent;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      cursor: pointer;
      outline: 0;
      padding: 0 22px 0 0;
      color: #fff; }
      .dd-header .dd-lnav select option {
        background: #006C8D; }
    .dd-header .dd-lnav select::-ms-expand {
      display: none; }
  .dd-header .dd-mobile-nav-button {
    display: none; }
    @media (max-width: 991.98px) {
      .dd-header .dd-mobile-nav-button {
        margin-left: 1rem;
        display: block;
        float: right;
        padding: .5em;
        cursor: pointer;
        font-size: 20px;
        -webkit-transition: margin-top .4s;
        transition: margin-top .4s; }
        .sticky-scrolled .dd-header .dd-mobile-nav-button {
          margin-top: -5px; } }

.dd-header .dd-social-links {
  display: none; }
  @media (min-width: 992px) {
    .dd-header .dd-social-links {
      display: block; } }

.search-wrapper {
  float: right;
  margin-left: 30px; }
  .dd-header .search-wrapper {
    display: none; }
    @media (min-width: 992px) {
      .dd-header .search-wrapper {
        display: block; } }
  .search-wrapper a {
    color: #fff; }
    .search-wrapper a:hover, .search-wrapper a:focus {
      text-decoration: none; }
    .search-wrapper a .link-text {
      border-bottom: 1px solid transparent;
      -webkit-transition: border .25s;
      transition: border .25s; }
    .search-wrapper a .link-icon {
      display: inline-block;
      margin-left: 4px;
      margin-top: 2px;
      -webkit-transition: -webkit-transform .25s;
      transition: -webkit-transform .25s;
      transition: transform .25s;
      transition: transform .25s, -webkit-transform .25s;
      position: relative;
      bottom: -2px; }
    .search-wrapper a:hover .link-text, .search-wrapper a:focus .link-text {
      border-color: #fff; }
    .search-wrapper a:hover .link-icon, .search-wrapper a:focus .link-icon {
      -webkit-transform: scale(1.275);
              transform: scale(1.275); }

.dd-social-links {
  margin-right: 30px;
  padding-right: 30px;
  position: relative;
  float: right; }
  .dd-social-links.nomargin {
    margin-right: 0;
    padding-right: 0; }
  .dd-header .dd-lnav ~ .dd-social-links:after {
    position: absolute;
    right: 0;
    top: 4px;
    content: ' ';
    display: block;
    width: 1px;
    background: rgba(255, 255, 255, 0.38);
    height: 1rem; }
  .dd-social-links li {
    display: block;
    float: left; }
  .dd-social-links a {
    display: block;
    float: left;
    margin: 0 0 0 26px;
    padding: 4px 2px 0;
    color: rgba(255, 255, 255, 0.66);
    -webkit-transition: color .25s, -webkit-transform .25s;
    transition: color .25s, -webkit-transform .25s;
    transition: color .25s, transform .25s;
    transition: color .25s, transform .25s, -webkit-transform .25s; }
    .dd-social-links a:hover, .dd-social-links a:active, .dd-social-links a:focus {
      text-decoration: none;
      color: #fff;
      -webkit-transform: scale(1.275);
              transform: scale(1.275); }

.dd-footer-color {
  background: #D7DADE; }

.dd-footer {
  padding: 60px 0 2rem 0; }
  .dd-footer .footer-col {
    margin-bottom: 35px;
    padding-bottom: 5rem; }
    .dd-footer .footer-col img {
      position: absolute;
      bottom: 0; }
  .dd-footer .dd-footer-title {
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 12px;
    display: block; }
  .dd-footer .dd-footer-logo {
    max-width: 150px;
    height: auto; }

.dd-footer-sec-color {
  background: #7C8488; }

.dd-footer-sec {
  padding: 19px 0 15px;
  font-size: 17px; }
  .dd-footer-sec, .dd-footer-sec address, .dd-footer-sec p {
    color: #fff;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .dd-footer-sec a, .dd-footer-sec a:focus, .dd-footer-sec a:hover, .dd-footer-sec a:active {
    color: #fff; }
  .dd-footer-sec .dd-fnav-sec li {
    float: left; }
    .dd-footer-sec .dd-fnav-sec li:after {
      content: ' ';
      margin-left: 10px;
      border-right: 1px solid #fff;
      margin-right: 10px;
      display: block;
      float: right;
      height: 70%;
      margin-top: 3px; }
    .dd-footer-sec .dd-fnav-sec li:last-child:after {
      content: normal; }
    .dd-footer-sec .dd-fnav-sec li a {
      border-bottom: 1px solid transparent;
      margin: 0;
      padding: 0 0 2px;
      -webkit-transition: border-color .3s;
      transition: border-color .3s; }
      .dd-footer-sec .dd-fnav-sec li a:hover, .dd-footer-sec .dd-fnav-sec li a:active {
        text-decoration: none;
        border-bottom-color: #fff; }

.dd-sidebar {
  display: none; }
  @media (min-width: 992px) {
    .dd-sidebar {
      display: block; } }
  .dd-sidebar .dd-snav {
    margin-bottom: 2rem; }
    .dd-sidebar .dd-snav > ul a {
      display: block;
      color: #212529;
      font-size: smaller;
      padding-bottom: 5px; }
      .dd-sidebar .dd-snav > ul a:hover, .dd-sidebar .dd-snav > ul a:focus, .dd-sidebar .dd-snav > ul a:active {
        text-decoration: none;
        color: #006C8D; }
    .dd-sidebar .dd-snav > ul li.active > a {
      color: #006C8D; }
    .dd-sidebar .dd-snav > ul > li .dd-snav-lv1-item {
      background: #dee2e6;
      position: relative;
      -webkit-transition: background .4s;
      transition: background .4s;
      margin-bottom: 1px;
      border-radius: 0.25rem;
      cursor: pointer; }
      .dd-sidebar .dd-snav > ul > li .dd-snav-lv1-item:hover, .dd-sidebar .dd-snav > ul > li .dd-snav-lv1-item.open {
        background: #adb5bd; }
      .dd-sidebar .dd-snav > ul > li .dd-snav-lv1-item a {
        padding: 10px 0 10px 10px;
        color: #212529; }
        .dd-sidebar .dd-snav > ul > li .dd-snav-lv1-item a:hover, .dd-sidebar .dd-snav > ul > li .dd-snav-lv1-item a.active {
          color: #212529; }
    .dd-sidebar .dd-snav > ul > li.has-child .dd-snav-lv1-item {
      padding-right: 30px; }
      .dd-sidebar .dd-snav > ul > li.has-child .dd-snav-lv1-item:after {
        position: absolute;
        right: 10px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: "basicfont" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\E902";
        margin-top: -2px; }
      .dd-sidebar .dd-snav > ul > li.has-child .dd-snav-lv1-item.open:after {
        content: "\E905"; }
    .dd-sidebar .dd-snav > ul > li.has-child.active > ul {
      display: block; }
    .dd-sidebar .dd-snav > ul > li > ul {
      display: none;
      padding: 10px; }
      .dd-sidebar .dd-snav > ul > li > ul > li > ul {
        margin-bottom: 0.5rem;
        display: none; }
        .dd-sidebar .dd-snav > ul > li > ul > li > ul li {
          padding-left: 20px; }
      .dd-sidebar .dd-snav > ul > li > ul > li.active > ul {
        display: block; }
        .dd-sidebar .dd-snav > ul > li > ul > li.active > ul li ul {
          display: none; }
        .dd-sidebar .dd-snav > ul > li > ul > li.active > ul li.active ul {
          display: block; }

label.required:after, legend.required:after {
  content: '*'; }

fieldset .custom-radio label.required:after, fieldset .custom-checkbox label.required:after {
  content: normal; }

.form-group-simple {
  margin-bottom: 29px; }
  .form-group-simple .form-control {
    border: none;
    border-radius: 0;
    height: 50px; }

.light-box .item {
  cursor: pointer; }

.lg-backdrop {
  background: #fff; }

.lg-outer .lg-item {
  background: url("/static/rey-technology/images/lg-loading.gif") no-repeat scroll center center transparent; }

.lg-actions .lg-next, .lg-actions .lg-prev, #lg-counter, .lg-toolbar .lg-icon {
  color: #fff; }

.element-upload.form-control {
  padding: 0;
  height: auto;
  font-size: inherit;
  color: inherit;
  background: transparent;
  background-clip: unset;
  border: none;
  border-radius: 0;
  -webkit-transition: unset;
  transition: unset; }

/*
    File Uploader
*/
.qq-upload-pause, .qq-upload-continue {
  background-color: #006C8D;
  color: #fff;
  border-color: #006C8D; }

.qq-upload-drop-area, .qq-upload-extra-drop-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 30px;
  z-index: 2;
  background: #f8f9fa;
  border-radius: 4px;
  border: 1px dashed #ced4da;
  text-align: center; }

.qq-upload-delete, .qq-upload-pause, .qq-upload-continue, .qq-upload-cancel, .qq-upload-retry, .qq-upload-size {
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  vertical-align: middle; }

.qq-btn {
  padding: 3px 4px;
  border: 1px solid #ced4da;
  border-radius: 2px;
  color: inherit;
  background-color: #fff; }

.qq-upload-delete {
  float: right;
  display: inline;
  background-color: #E40E1B;
  color: #fafafa;
  border-color: #E40E1B;
  margin-right: 12px; }
  .qq-upload-delete:hover {
    background-color: #E40E1B; }

.qq-upload-pause {
  display: inline;
  margin-right: 12px; }
  .qq-upload-pause:hover {
    background-color: #006C8D; }

.qq-upload-continue {
  display: inline;
  margin-right: 12px; }
  .qq-upload-continue:hover {
    background-color: #006C8D; }

.qq-upload-cancel {
  background-color: white;
  border-color: white;
  margin-right: 12px;
  display: inline; }
  .qq-upload-cancel:hover {
    background-color: white; }

.qq-upload-retry {
  background-color: white;
  border-color: white;
  margin-right: 12px;
  display: inline; }
  .qq-upload-retry:hover {
    background-color: white; }

.qq-upload-button {
  display: inline;
  padding: 7px 10px;
  text-align: center;
  float: left;
  background: #E04C23;
  color: #fff;
  border-radius: 0.25rem;
  border: none;
  border: 1px solid #E04C23;
  -webkit-transition: all .4s;
  transition: all .4s; }

.qq-upload-button-hover {
  background: #b63c1a;
  color: #fff; }

.qq-uploader {
  position: relative;
  max-height: 490px;
  overflow-y: hidden;
  width: inherit;
  border-radius: 6px;
  background-color: #f8f9fa;
  border: 1px dashed #ced4da;
  padding: 20px; }
  .qq-uploader:before {
    content: attr(qq-drop-area-text) " ";
    position: absolute;
    font-size: 200%;
    left: 0;
    width: 100%;
    text-align: center;
    opacity: 0.25; }
  .qq-uploader dialog {
    display: none; }
    .qq-uploader dialog .qq-dialog-buttons {
      text-align: center;
      padding-top: 10px; }
      .qq-uploader dialog .qq-dialog-buttons button {
        margin-left: 5px;
        margin-right: 5px; }
    .qq-uploader dialog .qq-dialog-message-selector {
      padding-bottom: 10px; }
  .qq-uploader dialog[open] {
    display: block; }
  .qq-uploader dialog::-webkit-backdrop {
    background-color: rgba(0, 0, 0, 0.7); }
  .qq-uploader dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.7); }

.qq-upload-drop-area span {
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -8px;
  font-size: 16px; }

.qq-upload-extra-drop-area {
  position: relative;
  margin-top: 50px;
  font-size: 16px;
  padding-top: 30px;
  height: 20px;
  min-height: 40px; }

.qq-upload-drop-area-active {
  background: #f8f9fa;
  border-radius: 4px;
  border: 1px dashed #ced4da; }

.qq-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 450px;
  overflow-y: auto;
  clear: both; }
  .qq-upload-list li {
    margin: 0;
    padding: 9px;
    line-height: 15px;
    font-size: 16px;
    color: #343a40;
    background-color: #e9ecef;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #adb5bd; }
    .qq-upload-list li:first-child {
      border-top: none; }
    .qq-upload-list li:last-child {
      border-bottom: none; }
    .qq-upload-list li.qq-upload-success {
      color: #424242;
      border-bottom: 1px solid #006C8D;
      border-top: 1px solid #f8f9fa; }
    .qq-upload-list li.qq-upload-fail {
      color: #424242;
      border-bottom: 1px solid #dc3545;
      border-top: 1px solid #f8f9fa; }

.qq-upload-file {
  margin-right: 12px;
  display: inline-block;
  width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 18px;
  line-height: 24px;
  font-size: smaller; }
  .qq-upload-file.qq-editable {
    cursor: pointer;
    margin-right: 4px; }

.qq-upload-spinner {
  margin-right: 12px;
  display: inline-block;
  background: url("/static/rey-technology/images/lg-loading.gif");
  width: 15px;
  height: 15px;
  vertical-align: text-bottom; }

.qq-upload-size {
  margin-right: 12px;
  display: inline; }

.qq-upload-failed-text {
  margin-right: 12px;
  display: none;
  font-style: italic;
  font-weight: bold; }

.qq-drop-processing {
  display: block; }

.qq-drop-processing-spinner {
  display: inline-block;
  background: url("/static/rey-technology/images/lg-loading.gif");
  width: 24px;
  height: 24px;
  vertical-align: text-bottom; }

.qq-upload-status-text {
  font-size: 14px;
  font-weight: bold;
  display: block; }

.qq-upload-failed-icon {
  display: none;
  width: 15px;
  height: 15px;
  vertical-align: text-bottom; }

.qq-progress-bar {
  display: block;
  background: #E04C23;
  width: 0;
  height: 5px;
  border-radius: 0;
  margin-bottom: 3px; }

.qq-total-progress-bar {
  display: none; }

.qq-total-progress-bar-container {
  margin-left: 9px;
  display: inline;
  float: right;
  width: 500px; }

.qq-edit-filename-icon {
  display: none; }

.qq-hide {
  display: none; }

.qq-thumbnail-selector {
  vertical-align: middle;
  margin-right: 12px; }

.qq-upload-fail .qq-upload-failed-text {
  display: inline; }

.qq-upload-retrying .qq-upload-failed-text {
  display: inline; }

input.qq-edit-filename {
  display: none; }

.slick-slider {
  opacity: 0;
  -webkit-transition: opacity .4s;
  transition: opacity .4s; }
  .slick-slider.ken-burns .slick-active img {
    -webkit-animation: zoomOut 8s ease 1;
            animation: zoomOut 8s ease 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .slick-slider.ken-burns .slick-slide[aria-hidden="true"] img {
    visibility: hidden; }
  .slick-slider.slick-initialized {
    opacity: 1; }
  .slick-slider img {
    width: 100%;
    height: auto;
    display: inline-block; }
  .slick-slider .dd-slick-arrow {
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    position: absolute;
    z-index: 50;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    display: none !important; }
    @media (min-width: 768px) {
      .slick-slider .dd-slick-arrow {
        display: block !important; } }
    .slick-slider .dd-slick-arrow.dd-slick-prev {
      left: 8px; }
    .slick-slider .dd-slick-arrow.dd-slick-next {
      right: 8px; }
  .slick-slider .dd-slick-arrow-reference {
    position: absolute;
    z-index: 50;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    display: none !important;
    top: 55%; }
    @media (min-width: 768px) {
      .slick-slider .dd-slick-arrow-reference {
        display: block !important;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); } }
    .slick-slider .dd-slick-arrow-reference.dd-slick-prev {
      left: 8px; }
    .slick-slider .dd-slick-arrow-reference.dd-slick-next {
      right: 8px; }
  .slick-slider.slick-slider-thumbs {
    margin-top: 8px; }
    .slick-slider.slick-slider-thumbs .slick-slide {
      cursor: pointer;
      padding: 0 4px; }

.dots-container .dd-slick-dots {
  position: absolute;
  bottom: 0;
  left: 0; }
  .dots-container .dd-slick-dots li {
    display: inline-block;
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: relative; }
    .dots-container .dd-slick-dots li:before {
      content: ' ';
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      position: absolute;
      width: 12px;
      height: 12px;
      background: rgba(255, 255, 255, 0.54);
      border-radius: 50%;
      -webkit-transition: background .4s;
      transition: background .4s; }
    .dots-container .dd-slick-dots li.slick-active:before {
      background: #fff; }
    .dots-container .dd-slick-dots li button {
      display: none; }
  .dots-container .dd-slick-dots.dd-slick-dots-inside {
    position: absolute;
    width: 100%;
    bottom: 10px; }

.dots-container ul.dd-slick-dots:not(.dd-slick-dots-inside) {
  display: none !important; }

.dd-header-slider {
  background: #006C8D; }
  .dd-header-slider .dd-header-slider-item {
    position: relative;
    max-width: 1440px;
    margin: 0 auto; }
    .dd-header-slider .dd-header-slider-item .dd-header-slider-content {
      position: absolute;
      width: 100%;
      bottom: 60px; }
      @media (min-width: 1600px) {
        .dd-header-slider .dd-header-slider-item .dd-header-slider-content {
          bottom: 100px; } }
      .dd-header-slider .dd-header-slider-item .dd-header-slider-content .dd-header-slider-title, .dd-header-slider .dd-header-slider-item .dd-header-slider-content .dd-header-slider-subtitle {
        display: inline-block;
        font-size: 28px;
        color: #fff;
        line-height: 1.1;
        font-weight: 700; }
        @media (min-width: 768px) {
          .dd-header-slider .dd-header-slider-item .dd-header-slider-content .dd-header-slider-title, .dd-header-slider .dd-header-slider-item .dd-header-slider-content .dd-header-slider-subtitle {
            font-size: 38px; } }
        @media (min-width: 1600px) {
          .dd-header-slider .dd-header-slider-item .dd-header-slider-content .dd-header-slider-title, .dd-header-slider .dd-header-slider-item .dd-header-slider-content .dd-header-slider-subtitle {
            font-size: 48px; } }
      .dd-header-slider .dd-header-slider-item .dd-header-slider-content .dd-header-slider-title {
        margin-bottom: .1em; }
      .dd-header-slider .dd-header-slider-item .dd-header-slider-content .dd-header-slider-subtitle {
        color: rgba(255, 255, 255, 0.48);
        margin-bottom: 15px; }
        @media (min-width: 1600px) {
          .dd-header-slider .dd-header-slider-item .dd-header-slider-content .dd-header-slider-subtitle {
            margin-bottom: 39px; } }

.sidr {
  background: #343a40;
  color: #fff;
  width: 100%; }
  .sidr.right {
    right: -100%; }
  .sidr .sidr-background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000 left bottom no-repeat;
    background-size: cover; }
  .sidr .gnav-mobile {
    overflow: hidden;
    padding: 29px 10px 34px; }
    .sidr .gnav-mobile .dd-mobile-nav-button {
      float: left; }
    .sidr .gnav-mobile .dd-social-links {
      margin-left: 15px;
      float: left; }
      .sidr .gnav-mobile .dd-social-links a {
        margin: 0 27px 0 0; }
    .sidr .gnav-mobile .search-wrapper {
      float: right;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      .sidr .gnav-mobile .search-wrapper a {
        color: #fff; }
    .sidr .gnav-mobile .dd-burger {
      margin-top: 0; }
  .sidr .dd-mnav {
    width: 100%;
    padding-left: 49px;
    float: right; }
    .sidr .dd-mnav a {
      display: block;
      color: #fff;
      text-decoration: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 16px; }
      .sidr .dd-mnav a.active:not(.active-trail), .sidr .dd-mnav a:hover, .sidr .dd-mnav a:focus, .sidr .dd-mnav a:active {
        color: #fff;
        text-decoration: none; }
    .sidr .dd-mnav > ul > li > .dd-mnav-lv1-item {
      background: transparent;
      position: relative;
      -webkit-transition: background .4s;
      transition: background .4s;
      border-top: 1px solid;
      border-color: #4B97AE; }
      .sidr .dd-mnav > ul > li > .dd-mnav-lv1-item a {
        padding: 0.5rem;
        color: #fff;
        font-size: 18px;
        font-weight: 500; }
        .sidr .dd-mnav > ul > li > .dd-mnav-lv1-item a.active, .sidr .dd-mnav > ul > li > .dd-mnav-lv1-item a:hover {
          color: #fff; }
      .sidr .dd-mnav > ul > li > .dd-mnav-lv1-item.active, .sidr .dd-mnav > ul > li > .dd-mnav-lv1-item:hover {
        background: #4B97AE;
        color: #fff; }
    .sidr .dd-mnav > ul > li.has-child > .dd-mnav-lv1-item {
      padding-right: 30px; }
      .sidr .dd-mnav > ul > li.has-child > .dd-mnav-lv1-item:after {
        position: absolute;
        right: 10px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: "basicfont" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\E902";
        color: #fff; }
    .sidr .dd-mnav > ul > li.has-child.is-open > .dd-mnav-lv1-item:after {
      content: "\E905"; }
    .sidr .dd-mnav > ul > li.has-child ul {
      padding: 0.5rem 1rem; }
      .sidr .dd-mnav > ul > li.has-child ul li {
        padding: 3px 0; }
        .sidr .dd-mnav > ul > li.has-child ul li a {
          line-height: 1.2; }
          .sidr .dd-mnav > ul > li.has-child ul li a.active, .sidr .dd-mnav > ul > li.has-child ul li a:hover, .sidr .dd-mnav > ul > li.has-child ul li a:focus {
            font-weight: 400; }
    .sidr .dd-mnav > ul > li.has-child > ul {
      display: none; }
    .sidr .dd-mnav > ul > li:last-child > .dd-mnav-lv1-item {
      border-bottom: 1px solid #4B97AE; }

.dd-burger {
  margin-top: .2em;
  width: 30px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }
  .dd-burger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
  .dd-burger span:nth-child(1) {
    top: 0; }
  .dd-burger span:nth-child(2), .dd-burger span:nth-child(3) {
    top: 10px; }
  .dd-burger span:nth-child(4) {
    top: 20px; }
  .sidr-mobile-open .dd-burger span:nth-child(1) {
    top: 10px;
    width: 0;
    left: 50%; }
  .sidr-mobile-open .dd-burger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .sidr-mobile-open .dd-burger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .sidr-mobile-open .dd-burger span:nth-child(4) {
    top: 10px;
    width: 0;
    left: 50%; }

@media (min-width: 992px) {
  .dd-tabcollapse-acc {
    display: none; } }

@media (max-width: 991.98px) {
  .dd-tabcollapse-tab {
    display: none; } }

.accordion .card {
  display: block; }

.toolbox-accordion .tab-content {
  padding: 0 1rem; }

.toolbox-accordion.component-tab .nav.nav-tabs {
  margin-bottom: 1rem; }

.toolbox-accordion .nav-tabs .nav-link.active, .toolbox-accordion .nav-tabs .nav-item.show .nav-link {
  background: #ECEEF0; }

.toolbox-accordion .card-header {
  padding: 0; }
  .toolbox-accordion .card-header a {
    display: block;
    padding: 0.75rem 1.25rem;
    position: relative; }
    .toolbox-accordion .card-header a:after {
      color: #006C8D;
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: "basicfont" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\E905";
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      right: 10px; }
    .toolbox-accordion .card-header a.collapsed:after {
      content: "\E902"; }
    .toolbox-accordion .card-header a, .toolbox-accordion .card-header a:focus, .toolbox-accordion .card-header a:active {
      text-decoration: none; }

.toolbox-accordion .card:first-of-type:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.toolbox-accordion .nav-tabs .nav-link {
  border-radius: 0; }

.toolbox-accordion .card, .toolbox-accordion .card-header {
  border-radius: 0; }

.toolbox-accordion .card-header {
  background: #ECEEF0;
  border: none; }

a.toolbox-anchor[id] {
  display: inline-block;
  position: relative;
  /*

    Activate this, if you have a static header to add a padding to anchor headlines.

    &:before {
      width:0;
      display: block;
      content: " ";
      margin-top: -175px;
      height: 175px;
      visibility: hidden;
      z-index: -1;
    }

  */ }

.toolbox-columns.equal-height > .row > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .toolbox-columns.equal-height > .row > div .toolbox-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .toolbox-columns.equal-height > .row > div .toolbox-column > .toolbox-image {
      overflow: hidden; }

.toolbox-columns.column-1-grey .row > div:nth-child(1) .toolbox-column {
  padding: 10px;
  background: #ECEEF0; }

.toolbox-columns.column-2-grey .row > div:nth-child(2) .toolbox-column {
  padding: 10px;
  background: #ECEEF0; }

.toolbox-columns.column-3-grey .row > div:nth-child(3) .toolbox-column {
  padding: 10px;
  background: #ECEEF0; }

.toolbox-columns.column-4-grey .row > div:nth-child(4) .toolbox-column {
  padding: 10px;
  background: #ECEEF0; }

.toolbox-container.bg-container, .toolbox-container.bg-container-dark {
  padding-top: 20px;
  padding-bottom: 20px; }
  @media (min-width: 768px) {
    .toolbox-container.bg-container, .toolbox-container.bg-container-dark {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media (min-width: 1200px) {
    .toolbox-container.bg-container, .toolbox-container.bg-container-dark {
      padding-top: 47px;
      padding-bottom: 58px; } }

.toolbox-container.bg-container {
  background: #ECEEF0; }

.toolbox-container.bg-container-dark {
  background: #E9ECEF; }

.toolbox-columns .toolbox-container.bg-container {
  padding: 0; }

.toolbox-columns .toolbox-container.bg-container .container {
  padding: 0; }

.toolbox-container.bg-container .container h4 {
  background-color: #b4d4de;
  text-transform: uppercase;
  height: 60px;
  padding: 20px;
  margin: 0; }

.toolbox-container.bg-container .container .download-list, .toolbox-container.bg-container .container .link-list {
  color: #006C8D; }
  .toolbox-container.bg-container .container .download-list a[class^="icon-download-"], .toolbox-container.bg-container .container .link-list a[class^="icon-download-"] {
    color: #006C8D;
    margin: 0;
    padding-left: 45px; }
    .toolbox-container.bg-container .container .download-list a[class^="icon-download-"] .file-info, .toolbox-container.bg-container .container .link-list a[class^="icon-download-"] .file-info {
      color: #393E41; }
    .toolbox-container.bg-container .container .download-list a[class^="icon-download-"]:before, .toolbox-container.bg-container .container .link-list a[class^="icon-download-"]:before {
      color: #006C8D;
      font-size: 32px;
      content: "\E90B"; }
  .toolbox-container.bg-container .container .download-list .item a, .toolbox-container.bg-container .container .link-list .item a {
    color: #006C8D;
    margin: 0;
    padding-left: 45px; }
    .toolbox-container.bg-container .container .download-list .item a .file-info, .toolbox-container.bg-container .container .link-list .item a .file-info {
      color: #393E41; }
    .toolbox-container.bg-container .container .download-list .item a:before, .toolbox-container.bg-container .container .link-list .item a:before {
      left: 20px;
      color: #006C8D;
      content: "\E904"; }
  .toolbox-container.bg-container .container .download-list ul li, .toolbox-container.bg-container .container .link-list ul li {
    padding: 20px;
    border-top: 1px solid #fff; }

.toolbox-download ul li a {
  display: block;
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 25px; }
  .toolbox-download ul li a, .toolbox-download ul li a:hover, .toolbox-download ul li a:focus, .toolbox-download ul li a:active {
    text-decoration: none;
    color: #212529; }
  .toolbox-download ul li a:hover {
    color: #006C8D; }
  .toolbox-download ul li a:before {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    left: 0;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "basicfont" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    content: "\E90B";
    color: #212529; }
  .toolbox-download ul li a.icon-download-pdf:before {
    content: "\E90C";
    color: #dc3545; }
  .toolbox-download ul li a.icon-download-docx:before, .toolbox-download ul li a.icon-download-doc:before, .toolbox-download ul li a.icon-download-odt:before {
    content: "\E910";
    color: #007bff; }
  .toolbox-download ul li a.icon-download-xlsx:before, .toolbox-download ul li a.icon-download-xls:before, .toolbox-download ul li a.icon-download-ods:before, .toolbox-download ul li a.icon-download-csv:before {
    content: "\E909";
    color: #28a745; }
  .toolbox-download ul li a.icon-download-pptx:before, .toolbox-download ul li a.icon-download-ppt:before, .toolbox-download ul li a.icon-download-odp:before {
    content: "\E90D"; }
  .toolbox-download ul li a.icon-download-jpg:before, .toolbox-download ul li a.icon-download-jpeg:before, .toolbox-download ul li a.icon-download-png:before, .toolbox-download ul li a.icon-download-svg:before, .toolbox-download ul li a.icon-download-svg:before, .toolbox-download ul li a.icon-download-tiff:before, .toolbox-download ul li a.icon-download-tif:before, .toolbox-download ul li a.icon-download-gif:before {
    content: "\E90A"; }
  .toolbox-download ul li a.icon-download-txt:before, .toolbox-download ul li a.icon-download-text:before {
    content: "\E90E"; }
  .toolbox-download ul li a.icon-download-mp4:before, .toolbox-download ul li a.icon-download-mpg:before, .toolbox-download ul li a.icon-download-mpeg:before, .toolbox-download ul li a.icon-download-webm:before, .toolbox-download ul li a.icon-download-ogv:before, .toolbox-download ul li a.icon-download-ogg:before, .toolbox-download ul li a.icon-download-avi:before, .toolbox-download ul li a.icon-download-mov:before, .toolbox-download ul li a.icon-download-webm:before {
    content: "\E90F"; }
  .toolbox-download ul li a.icon-download-mp3:before, .toolbox-download ul li a.icon-download-wav:before {
    content: "\E908"; }
  .toolbox-download ul li a .file-info {
    text-transform: uppercase;
    white-space: nowrap; }

.toolbox-download .show-image-preview ul li:first-child a {
  border-top: none;
  padding-top: 0;
  margin-top: 0; }

.toolbox-download .show-image-preview ul li a {
  border-top: 1px solid #ced4da;
  padding-top: 10px;
  margin-top: 10px;
  padding-left: 0;
  overflow: hidden; }
  .toolbox-download .show-image-preview ul li a:before {
    display: none; }
  .toolbox-download .show-image-preview ul li a .preview-image {
    display: block;
    float: left;
    border: 1px solid #ced4da;
    width: 80px;
    margin-right: 0.5rem; }
    .toolbox-download .show-image-preview ul li a .preview-image img {
      width: 100%;
      height: auto; }
  .toolbox-download .show-image-preview ul li a .file-info {
    display: block;
    font-size: smaller; }

.toolbox-download .download-list a[class^="icon-download-"], .toolbox-download .link-list a[class^="icon-download-"] {
  color: #006C8D;
  margin: 0;
  padding-left: 45px; }
  .toolbox-download .download-list a[class^="icon-download-"] .file-info, .toolbox-download .link-list a[class^="icon-download-"] .file-info {
    color: #393E41; }
  .toolbox-download .download-list a[class^="icon-download-"]:before, .toolbox-download .link-list a[class^="icon-download-"]:before {
    color: #006C8D;
    font-size: 32px;
    content: "\E90B"; }

.toolbox-download .download-list .item a, .toolbox-download .link-list .item a {
  color: #006C8D;
  margin: 0;
  padding-left: 45px; }
  .toolbox-download .download-list .item a .file-info, .toolbox-download .link-list .item a .file-info {
    color: #393E41; }
  .toolbox-download .download-list .item a:before, .toolbox-download .link-list .item a:before {
    left: 20px;
    color: #006C8D;
    content: "\E904"; }

.toolbox-headline a[id] {
  display: inline-block;
  position: relative;
  /*

        Activate this, if you have a static header to add a padding to anchor headlines.

        &:before {
          width:0;
          display: block;
          content: " ";
          margin-top: -175px;
          height: 175px;
          visibility: hidden;
          z-index: -1;
        }

      */ }

.toolbox-image {
  line-height: 1; }
  .toolbox-image img {
    width: 100%;
    height: auto; }
  .toolbox-image .caption {
    margin: 5px 0 8px 0;
    display: block;
    color: #6c757d;
    font-size: small; }

.toolbox-linklist ul li {
  position: relative;
  padding-left: 25px; }
  .toolbox-linklist ul li a {
    display: block;
    color: #212529; }
    .toolbox-linklist ul li a:hover, .toolbox-linklist ul li a:focus, .toolbox-linklist ul li a:active {
      color: #006C8D;
      text-decoration: none; }
    .toolbox-linklist ul li a:before {
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      left: 3px;
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: "basicfont" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 20px;
      content: "\E901";
      color: #212529; }

.toolbox-slide-columns > .row > .slide-columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden; }

.toolbox-spacer {
  margin: 0;
  padding: 0;
  line-height: 0; }
  .toolbox-spacer span {
    display: inline-block;
    width: 100%;
    height: 1px; }
    .toolbox-spacer span.spacer-50 {
      margin-top: 49px; }

.equal-height .toolbox-teaser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .equal-height .toolbox-teaser > .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .equal-height .toolbox-teaser > .row > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
  .equal-height .toolbox-teaser .single-teaser {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    max-width: 100%;
    min-width: 10px; }
    .equal-height .toolbox-teaser .single-teaser .bottom {
      margin-top: auto; }

.toolbox-teaser {
  background: #e9ecef; }
  .toolbox-teaser img {
    width: 100%; }
  .toolbox-teaser .teaser-content {
    padding: 10px 10px 5rem 10px; }
    .toolbox-teaser .teaser-content .teaser-link {
      position: absolute;
      bottom: 10px; }

.toolbox-video {
  position: relative;
  max-width: 100%; }
  .toolbox-video .video-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }
    .toolbox-video .video-inner iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .toolbox-video .poster-overlay {
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    background-size: cover; }
    .toolbox-video .poster-overlay .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-size: 80px;
      color: #fff;
      text-shadow: 0 0 3px #000;
      line-height: 1; }
      .toolbox-video .poster-overlay .icon:before {
        content: "\E915";
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: "basicfont" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }

.jobslider-wrapper .jobslider_image {
  width: 1443px;
  height: 484px;
  min-width: 100%;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.jobslider-wrapper .description {
  padding: 0 60px; }
  .jobslider-wrapper .description, .jobslider-wrapper .description p {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    color: #7C8488; }

.jobslider-wrapper .title {
  margin-bottom: 60px;
  font-weight: 300; }

.jobslider-wrapper .link {
  border-radius: 30px;
  padding: 10px 20px;
  background-color: #fff;
  text-transform: uppercase;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px; }

.jobslider-wrapper .graybg {
  background-color: #ECEEF0;
  padding: 60px 20px; }

.jobslider-wrapper .jobslider-header .title {
  font-size: 48px;
  margin-bottom: 50px; }

.jobslider-wrapper .jobslider-content {
  margin-top: -107px; }
  .jobslider-wrapper .jobslider-content .graybg {
    padding-bottom: 35px; }

.jobslider-wrapper h3, .jobslider-wrapper .jobslider_teaser {
  text-align: center; }

.jobslider-wrapper .slick-arrow:before, .jobslider-wrapper .slick-arrow:after {
  color: #006C8D; }

.jobslider-wrapper .dd-slick-arrow {
  border: 1px solid #006C8D; }
  .jobslider-wrapper .dd-slick-arrow.slick-disabled {
    display: none !important; }

#wrapper.jobdetail .dd-header.header-slider-header {
  background: #006C8D; }
  #wrapper.jobdetail .dd-header.header-slider-header + .content-wrapper {
    margin-top: 127px; }

#wrapper.jobdetail .jobslider_image {
  background-image: url("/static/rey-technology/images/jobslider_bg.png");
  width: 1443px;
  height: 484px;
  min-width: 100%;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 40px; }

#wrapper.jobdetail div.backlink {
  width: 100%;
  margin-bottom: 20px; }

#wrapper.jobdetail a.backlink {
  margin-bottom: 40px; }

#wrapper.jobdetail .lead {
  margin-bottom: 20px; }

#wrapper.jobdetail .logo {
  width: 300px;
  display: block; }

#wrapper.jobdetail a.logo {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px; }
  @media (min-width: 992px) {
    #wrapper.jobdetail a.logo {
      margin-bottom: 60px; } }

#wrapper.jobdetail .social-links {
  text-align: right;
  margin-bottom: 40px; }
  @media (min-width: 992px) {
    #wrapper.jobdetail .social-links {
      margin-bottom: 0; } }
  #wrapper.jobdetail .social-links .soclink {
    display: inline-block;
    color: #4B97AE; }
  #wrapper.jobdetail .social-links .label {
    float: left;
    line-height: 30px; }
  #wrapper.jobdetail .social-links .dd-social-links {
    float: left;
    margin: 0; }
    #wrapper.jobdetail .social-links .dd-social-links a {
      margin-left: 16px; }
      #wrapper.jobdetail .social-links .dd-social-links a:before {
        color: #4B97AE; }

.reference-container {
  background: #ECEEF0;
  padding: 80px 0; }

.reference-wrapper {
  margin-bottom: -80px; }
  .reference-wrapper .main-cat-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-bottom: 1px solid #7C8488; }
    .reference-wrapper .main-cat-wrapper .main-cat {
      height: 45px;
      background: #ECEEF0;
      color: #006C8D;
      -webkit-transition: color .35s, background .35s;
      transition: color .35s, background .35s;
      margin: 0;
      display: inline-block;
      padding: 12px 20px 8px 55px;
      cursor: pointer;
      text-align: center;
      position: relative;
      text-transform: uppercase; }
      .reference-wrapper .main-cat-wrapper .main-cat .main-cat-icon {
        font-size: 25px;
        position: absolute;
        left: 18px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .reference-wrapper .main-cat-wrapper .main-cat .angle-right {
        width: 0;
        height: 0;
        border-left: 46px solid transparent;
        border-bottom: 45px solid;
        border-bottom-color: #ECEEF0;
        position: absolute;
        top: 0;
        left: -46px;
        z-index: 1;
        -webkit-transition: border-bottom-color .35s;
        transition: border-bottom-color .35s; }
        .reference-wrapper .main-cat-wrapper .main-cat .angle-right.active {
          border-bottom: 45px solid #006C8D; }
      .reference-wrapper .main-cat-wrapper .main-cat .angle-left {
        width: 0;
        height: 0;
        border-right: 45px solid transparent;
        border-top: 45px solid;
        border-top-color: #ECEEF0;
        position: absolute;
        top: 0;
        right: -45px;
        z-index: 1;
        -webkit-transition: border-top-color .35s;
        transition: border-top-color .35s; }
        .reference-wrapper .main-cat-wrapper .main-cat .angle-left.active {
          border-top: 45px solid #006C8D; }
      .reference-wrapper .main-cat-wrapper .main-cat:hover, .reference-wrapper .main-cat-wrapper .main-cat:focus, .reference-wrapper .main-cat-wrapper .main-cat:active, .reference-wrapper .main-cat-wrapper .main-cat.active {
        background: #006C8D;
        color: #fff; }
        .reference-wrapper .main-cat-wrapper .main-cat:hover .angle-right, .reference-wrapper .main-cat-wrapper .main-cat:focus .angle-right, .reference-wrapper .main-cat-wrapper .main-cat:active .angle-right, .reference-wrapper .main-cat-wrapper .main-cat.active .angle-right {
          border-left: 46px solid transparent;
          border-bottom: 45px solid;
          border-bottom-color: #006C8D; }
        .reference-wrapper .main-cat-wrapper .main-cat:hover .angle-left, .reference-wrapper .main-cat-wrapper .main-cat:focus .angle-left, .reference-wrapper .main-cat-wrapper .main-cat:active .angle-left, .reference-wrapper .main-cat-wrapper .main-cat.active .angle-left {
          border-right: 45px solid transparent;
          border-top: 45px solid;
          border-top-color: #006C8D; }
      .reference-wrapper .main-cat-wrapper .main-cat:first-child {
        border-radius: 20px 0 0 0; }
      .reference-wrapper .main-cat-wrapper .main-cat:last-child {
        border-radius: 0 20px 0 0;
        margin-left: 45px; }
  .reference-wrapper .sub-cat-wrapper {
    max-width: 1920px;
    margin: 20px auto;
    position: relative; }
    .reference-wrapper .sub-cat-wrapper .sub-cat {
      background: #fff;
      border: 1px solid #393E41;
      color: #000;
      border-radius: 30px;
      -webkit-transition: color .35s, background .35s;
      transition: color .35s, background .35s;
      display: inline-block;
      padding: 12px 15px 10px 15px;
      cursor: pointer;
      text-align: center;
      margin: 0 10px 10px 0;
      min-width: 160px; }
      .reference-wrapper .sub-cat-wrapper .sub-cat::after {
        content: '\2573';
        padding-left: 10px;
        display: inline-block;
        -webkit-transition: top 0.1s, -webkit-transform 0.1s;
        transition: top 0.1s, -webkit-transform 0.1s;
        transition: transform 0.1s, top 0.1s;
        transition: transform 0.1s, top 0.1s, -webkit-transform 0.1s;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        position: relative;
        top: -4px; }
      .reference-wrapper .sub-cat-wrapper .sub-cat:hover, .reference-wrapper .sub-cat-wrapper .sub-cat:focus, .reference-wrapper .sub-cat-wrapper .sub-cat:active, .reference-wrapper .sub-cat-wrapper .sub-cat.active {
        border: 1px solid #006C8D;
        background: #006C8D;
        color: #fff; }
      .reference-wrapper .sub-cat-wrapper .sub-cat.active::after {
        content: '\2573';
        padding-left: 10px;
        display: inline-block;
        -webkit-transition: top 0.1s, -webkit-transform 0.1s;
        transition: top 0.1s, -webkit-transform 0.1s;
        transition: transform 0.1s, top 0.1s;
        transition: transform 0.1s, top 0.1s, -webkit-transform 0.1s;
        -webkit-transform: initial;
                transform: initial;
        position: relative;
        top: 0; }
  .reference-wrapper .isotope-grid {
    opacity: 0;
    -webkit-transition: opacity .4s;
    transition: opacity .4s; }
    .reference-wrapper .isotope-grid.sub-cat-wrapper {
      -webkit-transition: opacity .4s, width .35s ease;
      transition: opacity .4s, width .35s ease; }
    .reference-wrapper .isotope-grid.init {
      opacity: 1; }
  .reference-wrapper .reference-items-wrapper {
    max-width: 1920px;
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default; }
    .reference-wrapper .reference-items-wrapper .reference-item {
      width: 100%;
      background: #fff;
      height: 100%;
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      -webkit-transition: -webkit-box-shadow .35s;
      transition: -webkit-box-shadow .35s;
      transition: box-shadow .35s;
      transition: box-shadow .35s, -webkit-box-shadow .35s; }
      @media (min-width: 768px) {
        .reference-wrapper .reference-items-wrapper .reference-item {
          height: 500px; } }
      @media (min-width: 992px) {
        .reference-wrapper .reference-items-wrapper .reference-item {
          height: 450px; } }
      @media (min-width: 1200px) {
        .reference-wrapper .reference-items-wrapper .reference-item {
          height: 500px; } }
      .reference-wrapper .reference-items-wrapper .reference-item:hover {
        -webkit-box-shadow: 3px 3px 5px #D7DADE;
                box-shadow: 3px 3px 5px #D7DADE; }
      .reference-wrapper .reference-items-wrapper .reference-item .reference-image-wrapper {
        position: relative; }
        .reference-wrapper .reference-items-wrapper .reference-item .reference-image-wrapper .reference-image {
          width: 100%;
          height: auto; }
      .reference-wrapper .reference-items-wrapper .reference-item .reference-content {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 30px; }
        .reference-wrapper .reference-items-wrapper .reference-item .reference-content .reference-text {
          color: #393E41; }
        .reference-wrapper .reference-items-wrapper .reference-item .reference-content .reference-title {
          text-transform: initial;
          font-weight: 700; }
        .reference-wrapper .reference-items-wrapper .reference-item .reference-content .reference-readmore {
          margin-top: auto; }
          .reference-wrapper .reference-items-wrapper .reference-item .reference-content .reference-readmore img {
            position: relative;
            width: 20px;
            top: -2px; }

.reference-slider {
  background: #ECEEF0;
  padding: 80px 0;
  margin-bottom: -80px; }
  .reference-slider .reference-slider-headline {
    text-transform: initial;
    margin-bottom: 16px; }
  .reference-slider .slick-track {
    padding-bottom: 6px; }
  .reference-slider .slick-list {
    margin: 0 -10px; }
  .reference-slider .slick-slide {
    background: #fff;
    margin: 0 10px; }
    @media (min-width: 768px) {
      .reference-slider .slick-slide {
        height: 450px; } }
    @media (min-width: 992px) {
      .reference-slider .slick-slide {
        height: 470px; } }
    @media (min-width: 1200px) {
      .reference-slider .slick-slide {
        height: 500px; } }
    .reference-slider .slick-slide .reference-slider-content {
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding: 30px; }
      .reference-slider .slick-slide .reference-slider-content .reference-slider-text {
        color: #393E41; }
      .reference-slider .slick-slide .reference-slider-content .reference-slider-readmore {
        margin-top: auto; }
        .reference-slider .slick-slide .reference-slider-content .reference-slider-readmore img {
          position: relative;
          width: 20px;
          top: -2px; }
    .reference-slider .slick-slide > div {
      height: 100%; }
      .reference-slider .slick-slide > div > li {
        height: 100%;
        -webkit-transition: -webkit-box-shadow .35s;
        transition: -webkit-box-shadow .35s;
        transition: box-shadow .35s;
        transition: box-shadow .35s, -webkit-box-shadow .35s; }
        .reference-slider .slick-slide > div > li:hover {
          -webkit-box-shadow: 3px 3px 5px #D7DADE;
                  box-shadow: 3px 3px 5px #D7DADE; }
        .reference-slider .slick-slide > div > li > a {
          height: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-flex: 1;
              -ms-flex-positive: 1;
                  flex-grow: 1; }
  .reference-slider .dd-slick-arrow {
    display: block !important; }
  .reference-slider .dd-slick-prev {
    border: 1px solid #006C8D;
    color: #006C8D;
    -webkit-transform: translate(0, 240px);
            transform: translate(0, 240px); }
    @media (min-width: 480px) {
      .reference-slider .dd-slick-prev {
        -webkit-transform: translate(0, 300px);
                transform: translate(0, 300px); } }
    @media (min-width: 768px) {
      .reference-slider .dd-slick-prev {
        -webkit-transform: translate(0, 270px);
                transform: translate(0, 270px); } }
    @media (min-width: 992px) {
      .reference-slider .dd-slick-prev {
        -webkit-transform: translate(-60px, -50%);
                transform: translate(-60px, -50%); } }
    @media (min-width: 1200px) {
      .reference-slider .dd-slick-prev {
        -webkit-transform: translate(-100px, -50%);
                transform: translate(-100px, -50%); } }
  .reference-slider .dd-slick-next {
    border: 1px solid #006C8D;
    color: #006C8D;
    -webkit-transform: translate(0, 240px);
            transform: translate(0, 240px); }
    @media (min-width: 480px) {
      .reference-slider .dd-slick-next {
        -webkit-transform: translate(0, 300px);
                transform: translate(0, 300px); } }
    @media (min-width: 768px) {
      .reference-slider .dd-slick-next {
        -webkit-transform: translate(0, 270px);
                transform: translate(0, 270px); } }
    @media (min-width: 992px) {
      .reference-slider .dd-slick-next {
        -webkit-transform: translate(60px, -50%);
                transform: translate(60px, -50%); } }
    @media (min-width: 1200px) {
      .reference-slider .dd-slick-next {
        -webkit-transform: translate(100px, -50%);
                transform: translate(100px, -50%); } }
  .reference-slider .reference-back {
    margin-top: 120px; }
    @media (min-width: 992px) {
      .reference-slider .reference-back {
        margin-top: 30px; } }

.toolbox-separator hr {
  margin-bottom: 1.5rem; }

.toolbox-competence-teaser .teaser-link:hover, .toolbox-competence-teaser .teaser-link:active, .toolbox-competence-teaser .teaser-link:focus {
  text-decoration: none; }
  .toolbox-competence-teaser .teaser-link:hover .teaser-image, .toolbox-competence-teaser .teaser-link:active .teaser-image, .toolbox-competence-teaser .teaser-link:focus .teaser-image {
    background-color: #006C8D;
    -webkit-transition: all ease-in-out .1s;
    transition: all ease-in-out .1s; }
    .toolbox-competence-teaser .teaser-link:hover .teaser-image .teaser-icon .icon, .toolbox-competence-teaser .teaser-link:hover .teaser-image .teaser-icon .icon-hover, .toolbox-competence-teaser .teaser-link:active .teaser-image .teaser-icon .icon, .toolbox-competence-teaser .teaser-link:active .teaser-image .teaser-icon .icon-hover, .toolbox-competence-teaser .teaser-link:focus .teaser-image .teaser-icon .icon, .toolbox-competence-teaser .teaser-link:focus .teaser-image .teaser-icon .icon-hover {
      -webkit-transition: opacity ease-in-out .1s;
      transition: opacity ease-in-out .1s; }
    .toolbox-competence-teaser .teaser-link:hover .teaser-image .teaser-icon .icon, .toolbox-competence-teaser .teaser-link:active .teaser-image .teaser-icon .icon, .toolbox-competence-teaser .teaser-link:focus .teaser-image .teaser-icon .icon {
      opacity: 0; }
    .toolbox-competence-teaser .teaser-link:hover .teaser-image .teaser-icon .icon-hover, .toolbox-competence-teaser .teaser-link:active .teaser-image .teaser-icon .icon-hover, .toolbox-competence-teaser .teaser-link:focus .teaser-image .teaser-icon .icon-hover {
      opacity: 1; }

.toolbox-competence-teaser .teaser-link-icon {
  height: 15px; }

.toolbox-competence-teaser .teaser-image {
  background-color: #f8f9fa;
  -webkit-transition: all ease-in-out .1s;
  transition: all ease-in-out .1s; }
  .toolbox-competence-teaser .teaser-image .teaser-icon .icon, .toolbox-competence-teaser .teaser-image .teaser-icon .icon-hover {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: auto;
    opacity: 1;
    -webkit-transition: opacity ease-in-out .1s;
    transition: opacity ease-in-out .1s; }
  .toolbox-competence-teaser .teaser-image .teaser-icon .icon-hover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0; }

.toolbox-competence-teaser .teaser-content {
  margin-top: 1.5rem;
  padding-bottom: 3rem; }
  .toolbox-competence-teaser .teaser-content p {
    color: #000; }
  .toolbox-competence-teaser .teaser-content .teaser-link {
    position: absolute;
    bottom: 0; }

#categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  #categories .badge-rey-category {
    border-radius: 25px;
    font-size: 16px;
    color: #006C8D;
    background-color: #e9ecef;
    letter-spacing: 0.01px;
    padding: 5px 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer; }
    #categories .badge-rey-category.active {
      background-color: #006C8D;
      border-radius: 25px;
      font-size: 16px;
      color: #fff;
      letter-spacing: 0.01px; }

#person-container {
  max-width: 1920px;
  margin: 0 auto; }
  #person-container .person-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    position: relative; }
  #person-container .element {
    position: relative;
    margin: 1px;
    color: #fff;
    text-align: center;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    height: auto;
    background: #fff; }
    #person-container .element:before {
      content: '';
      float: left;
      padding-top: 100%; }
    #person-container .element.image {
      z-index: 4;
      background-color: #fff;
      -webkit-animation: 0.6s ease-out 0s 1 zoomIn;
              animation: 0.6s ease-out 0s 1 zoomIn; }
    #person-container .element.person {
      overflow: hidden;
      z-index: 3;
      background-color: #ECEEF0; }
      #person-container .element.person.person-left {
        -webkit-animation: 0.6s ease-out 0s 1 slideInFromLeft;
                animation: 0.6s ease-out 0s 1 slideInFromLeft; }
        #person-container .element.person.person-left:hover .spacer .icon {
          color: #fff;
          -webkit-transform: translateX(-20px);
                  transform: translateX(-20px); }
      #person-container .element.person.person-up {
        -webkit-animation: 0.6s ease-out 0s 1 slideInFromTop;
                animation: 0.6s ease-out 0s 1 slideInFromTop; }
        #person-container .element.person.person-up:hover .spacer .icon {
          color: #fff;
          -webkit-transform: translateY(-20px);
                  transform: translateY(-20px); }
      #person-container .element.person.person-down {
        -webkit-animation: 0.6s ease-out 0s 1 slideInFromBottom;
                animation: 0.6s ease-out 0s 1 slideInFromBottom; }
        #person-container .element.person.person-down:hover .spacer .icon {
          color: #fff;
          -webkit-transform: translateY(20px);
                  transform: translateY(20px); }
      #person-container .element.person.person-right {
        -webkit-animation: 0.6s ease-out 0s 1 slideInFromRight;
                animation: 0.6s ease-out 0s 1 slideInFromRight; }
        #person-container .element.person.person-right:hover .spacer .icon {
          color: #fff;
          -webkit-transform: translateX(20px);
                  transform: translateX(20px); }
      #person-container .element.person .person-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding: 20px;
        color: #212529;
        -webkit-transition: color .3s ease-out, background-color .3s ease-out;
        transition: color .3s ease-out, background-color .3s ease-out; }
        #person-container .element.person .person-container .spacer {
          -webkit-box-flex: 1;
              -ms-flex: 1 0 auto;
                  flex: 1 0 auto;
          height: 38px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; }
          #person-container .element.person .person-container .spacer .icon {
            font-size: 38px;
            color: #006C8D;
            -webkit-transition: color .4s ease-out, -webkit-transform .4s ease-out;
            transition: color .4s ease-out, -webkit-transform .4s ease-out;
            transition: color .4s ease-out, transform .4s ease-out;
            transition: color .4s ease-out, transform .4s ease-out, -webkit-transform .4s ease-out;
            -webkit-transform: translateX(0);
                    transform: translateX(0); }
        #person-container .element.person .person-container:hover {
          background-color: #006C8D;
          color: #fff; }
          #person-container .element.person .person-container:hover a {
            color: #fff; }
          #person-container .element.person .person-container:hover .spacer .icon {
            color: #fff; }
        #person-container .element.person .person-container .name {
          font-size: 17px;
          font-weight: bold;
          letter-spacing: 0.02px;
          text-align: center;
          line-height: 26px; }
        #person-container .element.person .person-container .position, #person-container .element.person .person-container .mail, #person-container .element.person .person-container .phone {
          font-size: 16px;
          letter-spacing: 0.01px;
          text-align: center;
          line-height: 20px; }
      #person-container .element.person span {
        display: block; }
    #person-container .element img, #person-container .element div.person-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%; }

@-webkit-keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoomIn {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.dd-news.dd-news-latest-slider .slick-slider .slick-slide {
  padding: 10px;
  background: #dee2e6; }

.dd-news.dd-news-latest-slider .slick-slider .dd-slick-dots {
  text-align: left;
  margin-top: .25em; }

.dd-news .download-list ul li, .dd-news .link-list ul li {
  padding: 20px;
  border-top: 1px solid #fff; }

.dd-news .news-entry {
  margin-bottom: 20px; }

.dd-news .toolbox-container.bg-container {
  padding: 0; }
  .dd-news .toolbox-container.bg-container .container {
    padding: 0; }
    .dd-news .toolbox-container.bg-container .container h4 {
      background-color: #b4d4de;
      height: 60px;
      padding: 20px;
      margin: 0; }

.dd-news .slick-arrow {
  display: block !important;
  border: 1px solid #006C8D;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 14px;
  top: 50%; }
  .dd-news .slick-arrow:before {
    color: #006C8D; }

.design-tiles, .design-tilelist {
  max-width: 2140px;
  margin-left: auto;
  margin-right: auto; }
  .design-tiles .news-entry, .design-tilelist .news-entry {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .design-tiles .news-entry .entry-image, .design-tilelist .news-entry .entry-image {
      min-height: 144px; }
      .design-tiles .news-entry .entry-image a, .design-tilelist .news-entry .entry-image a {
        display: block;
        width: 100%; }
        .design-tiles .news-entry .entry-image a picture, .design-tilelist .news-entry .entry-image a picture {
          display: block;
          width: 100%; }
          .design-tiles .news-entry .entry-image a picture .img-fluid, .design-tilelist .news-entry .entry-image a picture .img-fluid {
            width: 100%; }
    .design-tiles .news-entry .entry-content, .design-tilelist .news-entry .entry-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin: 30px;
      position: relative;
      padding-bottom: 15px; }
      .design-tiles .news-entry .entry-content div.news-link-more, .design-tilelist .news-entry .entry-content div.news-link-more {
        position: absolute;
        bottom: 0; }
      .design-tiles .news-entry .entry-content h3,
      .design-tiles .news-entry .entry-content h4,
      .design-tiles .news-entry .entry-content p, .design-tilelist .news-entry .entry-content h3,
      .design-tilelist .news-entry .entry-content h4,
      .design-tilelist .news-entry .entry-content p {
        -webkit-hyphens: auto;
            -ms-hyphens: auto;
                hyphens: auto; }
  .design-tiles .news-link-all, .design-tilelist .news-link-all {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }

.design-slider {
  width: 100%;
  height: 50%; }
  .design-slider .entry-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 60%;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 30px 20%;
    position: relative;
    padding-bottom: 15px;
    margin-top: 20px; }
    .design-slider .entry-content div.news-link-more {
      position: absolute;
      bottom: 0; }
    .design-slider .entry-content p {
      color: #7C8488; }
    .design-slider .entry-content * {
      text-align: center; }
  .design-slider .slick-slider.news-list-slider .slick-slide {
    width: 100%;
    padding: 0;
    background: unset; }
  .design-slider .news-list-gallery-slide {
    display: block; }
  .design-slider .slick-arrow {
    display: block !important;
    border: 1px solid #006C8D;
    background-color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 14px;
    top: unset;
    bottom: 100px; }
    .design-slider .slick-arrow:before {
      color: #006C8D; }
  .design-slider .dd-slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%; }
    .design-slider .dd-slick-dots li {
      display: inline-block;
      width: 25px;
      height: 25px;
      cursor: pointer;
      position: relative; }
      .design-slider .dd-slick-dots li:before {
        content: ' ';
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        position: absolute;
        width: 12px;
        height: 12px;
        background: rgba(255, 255, 255, 0.54);
        border-radius: 50%;
        -webkit-transition: background .4s;
        transition: background .4s; }
      .design-slider .dd-slick-dots li.slick-active:before {
        background: #fff; }
      .design-slider .dd-slick-dots li button {
        display: none; }
    .design-slider .dd-slick-dots.dd-slick-dots-inside {
      position: absolute;
      width: 100%;
      bottom: 10px; }

.news-related-list.design-tiles {
  margin-top: 40px; }

a.news-link-more:after {
  font-family: basicfont;
  content: "\E904"; }

.solution-wrapper .main-cat-wrapper {
  text-align: center; }
  .solution-wrapper .main-cat-wrapper .main-cat {
    background: #ECEEF0;
    color: #006C8D;
    border-radius: 30px;
    -webkit-transition: color .35s, background .35s;
    transition: color .35s, background .35s;
    margin: 0 6px 12px;
    display: inline-block;
    padding: 12px 20px 8px 55px;
    cursor: pointer;
    text-align: center;
    position: relative;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .solution-wrapper .main-cat-wrapper .main-cat {
        margin: 0 11px 22px; } }
    .solution-wrapper .main-cat-wrapper .main-cat .main-cat-icon {
      font-size: 25px;
      position: absolute;
      left: 18px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
    .solution-wrapper .main-cat-wrapper .main-cat:hover, .solution-wrapper .main-cat-wrapper .main-cat:focus, .solution-wrapper .main-cat-wrapper .main-cat:active, .solution-wrapper .main-cat-wrapper .main-cat.active {
      background: #006C8D;
      color: #fff; }

.solution-wrapper .sub-cat-wrapper {
  max-width: 1920px;
  margin: 0 auto;
  position: relative; }
  .solution-wrapper .sub-cat-wrapper .sub-cat {
    background: #ECEEF0;
    color: #006C8D;
    border-radius: 30px;
    -webkit-transition: color .35s, background .35s;
    transition: color .35s, background .35s;
    display: inline-block;
    padding: 5px 12px 2px;
    cursor: pointer;
    text-align: center;
    margin: 0 10px 10px 0;
    min-width: 160px; }
    .solution-wrapper .sub-cat-wrapper .sub-cat:hover, .solution-wrapper .sub-cat-wrapper .sub-cat:focus, .solution-wrapper .sub-cat-wrapper .sub-cat:active, .solution-wrapper .sub-cat-wrapper .sub-cat.active {
      background: #006C8D;
      color: #fff; }

.solution-wrapper .content-wrapper {
  position: relative;
  margin-bottom: 40px; }
  .solution-wrapper .content-wrapper .content-item {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
    -webkit-transition: opacity .4s ease, visibility 0s, -webkit-transform .4s ease;
    transition: opacity .4s ease, visibility 0s, -webkit-transform .4s ease;
    transition: opacity .4s ease, transform .4s ease, visibility 0s;
    transition: opacity .4s ease, transform .4s ease, visibility 0s, -webkit-transform .4s ease;
    min-height: 64px; }
    .solution-wrapper .content-wrapper .content-item.visible {
      opacity: 1;
      position: relative;
      visibility: visible;
      -webkit-transform: translate(0);
              transform: translate(0); }

.solution-wrapper .isotope-grid {
  opacity: 0;
  -webkit-transition: opacity .4s;
  transition: opacity .4s; }
  .solution-wrapper .isotope-grid.sub-cat-wrapper {
    -webkit-transition: opacity .4s, width .35s ease;
    transition: opacity .4s, width .35s ease; }
  .solution-wrapper .isotope-grid.init {
    opacity: 1; }

.solution-wrapper .solution-items-wrapper {
  max-width: 1920px;
  margin: 0 auto;
  min-height: 13vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .solution-wrapper .solution-items-wrapper .solution-item {
    padding: 40px 40px 40px 80px;
    width: 100%;
    position: relative;
    overflow: hidden;
    -webkit-transition: background-color .5s ease, border .75s ease-out;
    transition: background-color .5s ease, border .75s ease-out;
    border: 3px solid transparent;
    margin-bottom: 4px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .solution-wrapper .solution-items-wrapper .solution-item *, .solution-wrapper .solution-items-wrapper .solution-item h5 {
      color: #fff;
      -webkit-transition: color .35s ease-in;
      transition: color .35s ease-in; }
    .solution-wrapper .solution-items-wrapper .solution-item.solution-item-bg-1 {
      background: #006C8D; }
    .solution-wrapper .solution-items-wrapper .solution-item.solution-item-bg-2 {
      background: #0080a7; }
    .solution-wrapper .solution-items-wrapper .solution-item.solution-item-bg-3 {
      background: #0093c0; }
    .solution-wrapper .solution-items-wrapper .solution-item .solution-icon {
      position: absolute;
      font-size: 44px;
      top: 20px;
      left: 16px;
      -webkit-transition: color .45s ease, -webkit-transform .7s ease-out;
      transition: color .45s ease, -webkit-transform .7s ease-out;
      transition: transform .7s ease-out, color .45s ease;
      transition: transform .7s ease-out, color .45s ease, -webkit-transform .7s ease-out;
      -webkit-transform-origin: right bottom;
              transform-origin: right bottom; }
    .solution-wrapper .solution-items-wrapper .solution-item .solution-link-icon {
      display: inline-block;
      margin-left: 10px;
      position: relative;
      bottom: -2px;
      -webkit-transition: -webkit-transform .4s ease;
      transition: -webkit-transform .4s ease;
      transition: transform .4s ease;
      transition: transform .4s ease, -webkit-transform .4s ease; }
    .solution-wrapper .solution-items-wrapper .solution-item .solution-cat {
      display: inline-block;
      text-transform: uppercase;
      margin-bottom: 19px; }
    .solution-wrapper .solution-items-wrapper .solution-item .solution-image-wrapper {
      position: relative;
      text-align: right; }
      .solution-wrapper .solution-items-wrapper .solution-item .solution-image-wrapper .solution-image {
        -webkit-transition: opacity .35s ease;
        transition: opacity .35s ease; }
        .solution-wrapper .solution-items-wrapper .solution-item .solution-image-wrapper .solution-image.solution-image-color {
          position: absolute;
          top: 0;
          right: 0;
          opacity: 0; }
    .solution-wrapper .solution-items-wrapper .solution-item:hover {
      background-color: #fff;
      border-color: #006C8D; }
      .solution-wrapper .solution-items-wrapper .solution-item:hover *, .solution-wrapper .solution-items-wrapper .solution-item:hover h5 {
        color: #006C8D; }
      .solution-wrapper .solution-items-wrapper .solution-item:hover .solution-icon {
        -webkit-transform: scale(1.9) rotate(20deg) translateY(30%);
                transform: scale(1.9) rotate(20deg) translateY(30%); }
      .solution-wrapper .solution-items-wrapper .solution-item:hover .solution-link-icon {
        -webkit-transform: translateX(25px);
                transform: translateX(25px); }
      .solution-wrapper .solution-items-wrapper .solution-item:hover .solution-image-wrapper .solution-image.solution-image-grey {
        opacity: 0; }
      .solution-wrapper .solution-items-wrapper .solution-item:hover .solution-image-wrapper .solution-image.solution-image-color {
        opacity: 1; }

.solution-wrapper.logo-template .solution-items-wrapper .solution-item {
  padding: 60px 40px;
  text-align: center; }
  .solution-wrapper.logo-template .solution-items-wrapper .solution-item h5.solution-title-center {
    margin-bottom: 0; }
  .solution-wrapper.logo-template .solution-items-wrapper .solution-item .solution-image-wrapper {
    text-align: center; }
    .solution-wrapper.logo-template .solution-items-wrapper .solution-item .solution-image-wrapper .solution-image.solution-image-color {
      left: 50%;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0); }

.homeslider-wrapper {
  margin-bottom: 60px; }
  .homeslider-wrapper div[class^="col-"] {
    padding: 0; }
  .homeslider-wrapper .home-slider {
    width: 100%;
    height: 418px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    overflow: hidden; }
    .homeslider-wrapper .home-slider .image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .homeslider-wrapper .home-slider .content-slider {
      -webkit-transition: -webkit-transform .48s ease;
      transition: -webkit-transform .48s ease;
      transition: transform .48s ease;
      transition: transform .48s ease, -webkit-transform .48s ease;
      -webkit-transform: translateY(264px);
              transform: translateY(264px); }
      @media (min-width: 768px) {
        .homeslider-wrapper .home-slider .content-slider {
          -webkit-transform: translateY(270px);
                  transform: translateY(270px); } }
      .homeslider-wrapper .home-slider .content-slider .info {
        -webkit-transition: opacity .45s ease;
        transition: opacity .45s ease;
        opacity: 0; }
      .homeslider-wrapper .home-slider .content-slider .icon {
        display: block;
        width: 120px;
        height: 120px; }
        .homeslider-wrapper .home-slider .content-slider .icon img {
          width: 100%;
          height: auto; }
      .homeslider-wrapper .home-slider .content-slider .title {
        color: #fff;
        margin-top: 20px;
        margin-left: 80px;
        margin-right: 70px;
        margin-bottom: 30px;
        position: relative; }
        @media (min-width: 992px) {
          .homeslider-wrapper .home-slider .content-slider .title {
            margin-bottom: 20px;
            margin-right: 20px;
            margin-left: 120px; } }
        .homeslider-wrapper .home-slider .content-slider .title:before, .homeslider-wrapper .home-slider .content-slider .title .icon {
          display: block;
          position: absolute;
          fill: #fff;
          content: ' ';
          left: -56px;
          top: -12px;
          width: 48px;
          height: 48px;
          background-image: var(--icon-src);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat; }
          @media (min-width: 992px) {
            .homeslider-wrapper .home-slider .content-slider .title:before, .homeslider-wrapper .home-slider .content-slider .title .icon {
              left: -80px;
              top: -20px;
              width: 65px;
              height: 65px; } }
      .homeslider-wrapper .home-slider .content-slider .info {
        margin-left: 80px;
        margin-right: 60px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        @media (min-width: 992px) {
          .homeslider-wrapper .home-slider .content-slider .info {
            margin-left: 120px;
            margin-right: 120px; } }
        .homeslider-wrapper .home-slider .content-slider .info .description {
          color: #fff;
          min-height: 200px;
          max-height: 250px;
          -webkit-hyphens: auto;
              -ms-hyphens: auto;
                  hyphens: auto;
          text-align: justify;
          overflow-y: hidden; }
          @media (min-width: 768px) {
            .homeslider-wrapper .home-slider .content-slider .info .description {
              min-height: 200px;
              max-height: 200px; } }
        .homeslider-wrapper .home-slider .content-slider .info .link {
          color: #fff; }
          .homeslider-wrapper .home-slider .content-slider .info .link:after {
            display: inline-block;
            position: relative;
            bottom: -3px;
            font-family: basicfont;
            content: "\E904";
            color: #fff;
            margin-left: 10px;
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            -webkit-transition: -webkit-transform .5s .2s ease-out;
            transition: -webkit-transform .5s .2s ease-out;
            transition: transform .5s .2s ease-out;
            transition: transform .5s .2s ease-out, -webkit-transform .5s .2s ease-out; }
    .homeslider-wrapper .home-slider:hover .content-slider {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px); }
      @media (min-width: 768px) {
        .homeslider-wrapper .home-slider:hover .content-slider {
          -webkit-transform: translateY(-30px);
                  transform: translateY(-30px); } }
      .homeslider-wrapper .home-slider:hover .content-slider .info {
        opacity: 1; }
        .homeslider-wrapper .home-slider:hover .content-slider .info .link:after {
          -webkit-transform: translateX(25px);
                  transform: translateX(25px); }
  .homeslider-wrapper .slick-slider .slick-arrow {
    display: block !important;
    border: 1px solid #006C8D;
    background-color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 14px;
    margin: 4px;
    top: 50%; }
    @media (min-width: 992px) {
      .homeslider-wrapper .slick-slider .slick-arrow {
        margin: 14px; } }
    .homeslider-wrapper .slick-slider .slick-arrow:first-child {
      padding: 14px 12px; }
    .homeslider-wrapper .slick-slider .slick-arrow:before {
      color: #006C8D; }

.titlebox-wrapper h4 {
  padding: 20px;
  background-color: #b4d4de;
  margin-bottom: 1px; }

.titlebox-wrapper .titlebox-content {
  padding: 20px 20px 1px;
  background: #ECEEF0; }
  .titlebox-wrapper .titlebox-content .toolbox-element.toolbox-element {
    margin-bottom: 20px !important; }

